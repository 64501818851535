import { useBidPageQuery } from "@/graphql";
import { ErrorWrapper } from "@/modules/Error";
import { DetailPageLayout } from "@/modules/Layout";
import { useMemo } from "react";
import { mapToBid } from "./maps";
import { BidData, BidPageSkeleton } from ".";
import { BidOverview } from "./components/BidOverview";
import { BidRightPanel } from "./components/BidRightPanel";

type BidPageProps = {
  id: string;
};

export function BidPage({ id }: BidPageProps) {
  const [{ data, error, fetching }] = useBidPageQuery({
    variables: { id },
  });

  const bid: BidData | null = useMemo(() => {
    if (!data?.bid) return null;

    const formattedBid = mapToBid(data.bid);

    return [data.bid, formattedBid];
  }, [data]);

  if (fetching) {
    return <BidPageSkeleton />;
  }

  return (
    <ErrorWrapper error={error?.message}>
      <DetailPageLayout
        isTwoColumn
        mainPanel={bid && <BidOverview bid={bid} />}
        rightPanel={bid && <BidRightPanel bid={bid} />}
      />
    </ErrorWrapper>
  );
}
