import { Table, TableColumns } from "@/modules/Table";
import { Card, CardBody, CardHeader } from "@chakra-ui/react";
import i18next from "i18next";
import { ReactNode, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { BidFragment, BidListingFragment } from "@/graphql";
import { useRouter } from "next/router";
import { Row } from "@tanstack/react-table";
import { ListingsTableRowActions } from "@/features/Listings";
import {
  ListingIdTableCell,
  SharesPPSTableCell,
  SellerEmailTableCell,
  PlacedOnTableCell,
  StateTableCell,
} from "./BidListingsTableCells";

type BidListingsCardProps = {
  bid: BidFragment;
};

type BidListingsTableColumn = Record<
  "id" | "sharesPPS" | "sellerEmail" | "placedOn" | "state",
  ReactNode
>;
type BidListingsTableData = BidListingFragment;

const EMPTY_STATE_BID = [{}];

const columns: TableColumns<BidListingsTableColumn, BidListingsTableData> = {
  id: {
    header: () => i18next.t(`listing_id`),
    cell: ({ cell }) => <ListingIdTableCell cell={cell} />,
    enableSorting: false,
  },
  sharesPPS: {
    header: () => i18next.t(`shares_pps`),
    cell: ({ cell }) => <SharesPPSTableCell cell={cell} />,
    enableSorting: false,
  },
  sellerEmail: {
    header: () => i18next.t(`seller_email`),
    cell: ({ cell }) => <SellerEmailTableCell cell={cell} />,
    enableSorting: false,
  },
  placedOn: {
    header: () => i18next.t(`placed_on`),
    cell: ({ cell }) => <PlacedOnTableCell cell={cell} />,
    enableSorting: false,
  },
  state: {
    header: () => i18next.t(`state`),
    cell: ({ cell }) => <StateTableCell cell={cell} />,
    enableSorting: false,
  },
};

export function BidListingsCard({ bid }: BidListingsCardProps) {
  const { t } = useTranslation();
  const { push } = useRouter();

  const onRowClick = useCallback(
    (listingRow: Row<BidListingsTableData>) => {
      const { id } = listingRow.original;
      push(`/listings/${id}`);
    },
    [push],
  );

  const renderRowActions = useCallback(
    (listingRow: Row<BidListingsTableData>) => (
      <ListingsTableRowActions
        listingId={listingRow.original.id}
        solicited={listingRow.original.solicited}
        companyState={listingRow.original.company.status}
        state={listingRow.original.state}
      />
    ),
    [],
  );

  const data = (
    bid?.listing ? [bid.listing] : EMPTY_STATE_BID
  ) as BidListingsTableData[];

  return (
    <Card w="full">
      <CardHeader textStyle="colfax-22-medium">
        {t(`listing_for_this_bid`)}
      </CardHeader>
      <CardBody>
        <Table<BidListingsTableColumn[], BidListingsTableData[]>
          columns={columns}
          data={data}
          borderColor="grey.100"
          borderStyle="solid"
          borderBottomWidth={1}
          borderLeftWidth={1}
          borderRightWidth={1}
          onRowClick={onRowClick}
          renderRowActions={renderRowActions}
        />
      </CardBody>
    </Card>
  );
}
