import { ListingDetailsContext } from "@/features/Listing";
import { FeeDiscountEntityType } from "@/graphql";
import { FeeDiscountsCard } from "@/modules/FeeDiscountsCard";
import { VStack } from "@chakra-ui/react";
import { useContext } from "react";
import { ListingSecuritySpecialistCard } from "./ListingSecuritySpecialistCard";
import { ListingStatusCard } from "./ListingStatusCard";

export default function ListingRightPanel() {
  const { listing, refetch } = useContext(ListingDetailsContext);

  if (!listing) return null;

  const { activeFeeDiscountApplications, company, id, seller, state } =
    listing || {};

  return (
    <VStack gap={4}>
      <ListingStatusCard />
      <ListingSecuritySpecialistCard />
      {id && (
        <FeeDiscountsCard
          entity={{
            company,
            entityId: id,
            entityType: FeeDiscountEntityType.Listing,
            listing,
            seller,
            state,
          }}
          feeDiscountApplications={activeFeeDiscountApplications || []}
          onSuccess={refetch}
        />
      )}
    </VStack>
  );
}
