import {
  BidsTableColumnType,
  BidsTableContext,
  BidsTableDataType,
  mapToTable,
} from "@/features/Bids";
import { BidStatus, BidTableRowActions } from "@/features/Bid";
import { BidsTableNodeFragment } from "@/graphql";
import { Input } from "@/modules/Form";
import {
  SimpleTable,
  SimpleTableQueryVariables,
  SimpleTableUrlParams,
  TableContext,
} from "@/modules/SimpleTable";
import { Status } from "@/modules/Status";
import {
  IndicatorColor,
  Table,
  TableColumns,
  TableTooltipCell,
} from "@/modules/Table";
import {
  Flex,
  HStack,
  Tab,
  VStack,
  Button,
  Spacer,
  Wrap,
} from "@chakra-ui/react";
import { Row } from "@tanstack/react-table";
import i18next from "i18next";
import { useRouter } from "next/router";
import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import { RESET_PAGINATION_PARAMS } from "@/constants";
import { FilterTag } from "@/modules/FilterTag";
import BidsSecuritySpecialistFilterButton from "./BidsSecuritySpecialistFilterButton";

export type BidsUrlParams = SimpleTableUrlParams & {
  status?: string;
  page: string;
  before: string;
  after: string;
  first: string;
  last: string;
};

const columns: TableColumns<BidsTableColumnType, BidsTableDataType> = {
  displayId: {
    header: () => i18next.t(`bid_id`),
  },
  listingDisplayId: {
    header: () => i18next.t(`listing_id`),
  },
  companyName: {
    header: () => i18next.t(`company`),
    cell: ({ cell, column: { id } }) => (
      <TableTooltipCell id={id} text={cell.getValue()} />
    ),
  },
  securitySpecialistName: {
    header: () => i18next.t(`security_specialist`),
    cell: ({ cell, column: { id } }) => (
      <TableTooltipCell id={id} text={cell.getValue()} />
    ),
  },
  sharesPPS: {
    header: () => i18next.t(`shares_pps`),
    enableSorting: false,
  },
  buyerEmail: {
    header: () => i18next.t(`buyer_email`),
    cell: ({ cell, column: { id } }) => (
      <TableTooltipCell id={id} text={cell.getValue()} />
    ),
  },
  insertedAt: {
    header: () => i18next.t(`created_on`),
  },
  lastUpdated: {
    header: () => i18next.t(`last_updated`),
    enableSorting: false,
  },
  solicited: {
    header: () => i18next.t(`solicitation`),
    cell: ({ cell }) =>
      match<boolean>(cell.getValue())
        .with(true, () => (
          <Status.Indicator
            text={i18next.t(`solicited`)}
            indicatorProps={{ bg: IndicatorColor.LISTING_TYPE }}
          />
        ))
        .with(false, () => (
          <Status.Indicator
            text={i18next.t(`unsolicited`)}
            indicatorProps={{ bg: IndicatorColor.LISTING_TYPE }}
          />
        ))
        .exhaustive(),
    enableSorting: false,
  },
  state: {
    header: () => i18next.t(`state`),
    cell: ({ cell }) => {
      const state = cell.getValue();

      return <BidStatus state={state} />;
    },
    enableSorting: false,
  },
};

function BidsTableTabs() {
  const { t } = useTranslation();

  return (
    <Table.Tabs defaultIndex={0}>
      <Tab>{t(`all_bids`)}</Tab>
    </Table.Tabs>
  );
}

function BidsTableSearch() {
  const { register } = useContext(TableContext);
  const { t } = useTranslation();

  return (
    <HStack alignItems="center">
      <Input.Search
        placeholder={t(`search_by_bid_id`)}
        w={430}
        {...register(`searchText`)}
      />
    </HStack>
  );
}

function BidsTableHeader() {
  const { t } = useTranslation();
  const { data } = useContext(BidsTableContext);
  const securitySpecialists = data?.data?.securitySpecialists || [];

  const { variables, setVariables, resetField } = useContext(TableContext);

  const onClearFilters = () => {
    setVariables((prevVariables) => ({
      ...prevVariables,
      filterBy: {
        ...prevVariables.filterBy,
        securitySpecialistUserIds: [],
      },
      ...RESET_PAGINATION_PARAMS,
    }));
    resetField(`securitySpecialistCheckbox`);
  };

  const selectedSecuritySpecialists =
    securitySpecialists?.filter(
      (securitySpecialist) =>
        variables.filterBy?.securitySpecialistUserIds?.includes(
          securitySpecialist.id,
        ),
    ) ?? [];

  const onRemoveSecuritySpecialistFilter = (
    omitSecuritySpecialistId: string,
  ) => {
    setVariables((prevVariables) => ({
      ...prevVariables,
      filterBy: {
        ...prevVariables.filterBy,
        securitySpecialistUserIds:
          prevVariables.filterBy?.securitySpecialistUserIds?.filter(
            (securitySpecialistId) =>
              securitySpecialistId !== omitSecuritySpecialistId,
          ),
      },
      ...RESET_PAGINATION_PARAMS,
    }));
    resetField(
      `securitySpecialistCheckbox.${omitSecuritySpecialistId}` as "securitySpecialistCheckbox.string",
    );
  };

  return (
    <Flex
      justifyContent="space-between"
      w="full"
      bg="white"
      p={4}
      borderTopRightRadius={8}
    >
      <VStack spacing={4} width="full">
        <HStack justifyContent="spaceBetween" width="full">
          <BidsTableSearch />
          <Spacer />
          <BidsSecuritySpecialistFilterButton
            securitySpecialists={securitySpecialists}
          />
        </HStack>
        {selectedSecuritySpecialists?.length > 0 && (
          <HStack w="full">
            <Wrap>
              {selectedSecuritySpecialists?.map((securitySpecialist) => (
                <FilterTag
                  key={securitySpecialist?.id}
                  name={`${securitySpecialist?.name}`}
                  onClick={() =>
                    onRemoveSecuritySpecialistFilter(securitySpecialist.id)
                  }
                />
              ))}
              <Button variant="link" onClick={onClearFilters}>
                {t(`clear_filters`)}
              </Button>
            </Wrap>
          </HStack>
        )}
      </VStack>
    </Flex>
  );
}

export function BidsTable() {
  const { push } = useRouter();
  const { page, searchText, setSort, setVariables, sort } =
    useContext(TableContext);
  const { data } = useContext(BidsTableContext);

  const onRowClick = useCallback(
    (row: Row<Record<string, unknown>>) => {
      const { id } = row.original as BidsTableNodeFragment;
      push(`/bids/${id}`);
    },
    [push],
  );

  const tableData = useMemo(() => mapToTable(data), [data]);

  const renderRowActions = useCallback(
    (row: Row<BidsTableDataType>) => (
      <BidTableRowActions
        bidId={row.original.id as string}
        solicited={row.original.solicited}
        companyState={row.original.company?.status}
        state={row.original.state}
      />
    ),
    [],
  );

  if (!data) {
    return null;
  }

  return (
    <>
      <BidsTableTabs />
      <BidsTableHeader />
      <SimpleTable<
        SimpleTableQueryVariables,
        BidsTableColumnType[],
        BidsTableDataType[]
      >
        page={page}
        sort={sort}
        setSort={setSort}
        searchText={searchText}
        setVariables={setVariables}
        columns={columns}
        tableData={tableData.bids}
        pageInfo={tableData.pageInfo}
        totalCount={tableData.totalCount}
        error={data.error}
        loading={data.fetching}
        onRowClick={onRowClick}
        // @ts-expect-error: This is a result of a bad generic typing
        renderRowActions={renderRowActions}
      />
    </>
  );
}
