import { BidData } from "@/features/Bid";
import { VStack } from "@chakra-ui/react";
import { BidStatusCard } from "./BidStatusCard";
import { BidSecuritySpecialistCard } from "./BidSecuritySpecialistsCard";

type BidRightPanelProps = {
  bid: BidData;
};

export function BidRightPanel({ bid }: BidRightPanelProps) {
  const [
    { id, displayId, ignoreInPricing, company },
    { formattedPlacedAt, formattedExpires, formattedStatus },
  ] = bid;
  return (
    <VStack gap={4}>
      <BidStatusCard
        id={id}
        displayId={displayId}
        ignoreInPricing={ignoreInPricing}
        formattedPlacedAt={formattedPlacedAt}
        formattedExpires={formattedExpires}
        formattedStatus={formattedStatus ?? ``}
      />
      <BidSecuritySpecialistCard company={company} />
    </VStack>
  );
}
