import {
  BidBrokerFragment,
  BidBuyerFragment,
  BidRepresentedUserFragment,
  InvestorDescriptor,
  Maybe,
} from "@/graphql";
import { CardTable } from "@/modules/CardTable";
import { CopyToClipboard } from "@/modules/CopyToClipboard";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Tag,
  TagProps,
  Text,
  VStack,
} from "@chakra-ui/react";
import { CaretRight } from "@phosphor-icons/react";
import { capitalCase } from "change-case";
import i18next from "i18next";
import Link from "next/link";
import { useTranslation } from "react-i18next";

type UserDetailsProps = {
  id?: string;
  name?: Maybe<string>;
  email?: Maybe<string>;
  investorDescriptor: InvestorDescriptor;
  variant: "hiiveUser" | "default";
};

type RepresentedUserDetailsProps = BidRepresentedUserFragment;

type BidBuyerCardTableProps = {
  buyer: BidBuyerFragment;
  representedUser?: Maybe<BidRepresentedUserFragment>;
  broker?: Maybe<BidBrokerFragment>;
  placedByHiive: boolean;
};

const TAG_VARIANT: Record<
  string,
  { variant: TagProps["variant"]; text?: string }
> = {
  hiiveUser: { variant: `lightBlue`, text: i18next.t(`hiive`) },
  default: { variant: `grey` },
};

function UserDetails({
  id,
  name,
  email,
  investorDescriptor,
  variant,
}: UserDetailsProps) {
  const { t } = useTranslation();
  const tagVariant = TAG_VARIANT[variant];

  return (
    <Box
      backgroundColor="grey.25"
      borderColor="grey.50"
      borderWidth={1}
      borderRadius={6}
      p={3}
      maxW={400}
      flex={1}
    >
      <VStack alignItems="flex-start" gap={1} marginBottom={4}>
        {investorDescriptor && (
          <Tag size="sm" variant={tagVariant.variant}>
            {tagVariant.text || capitalCase(investorDescriptor)}
          </Tag>
        )}
        <Text fontWeight="500">{name}</Text>
        {email && <CopyToClipboard value={email} />}
      </VStack>
      <Link href={`/users/${id}`}>
        <Button variant="outline" size="md">
          <HStack>
            <Text textStyle="colfax-12-medium">{t`full_details`}</Text>
            <CaretRight size={14} />
          </HStack>
        </Button>
      </Link>
    </Box>
  );
}

function RepresentedUserDetails({
  email,
  firstName,
  lastName,
}: RepresentedUserDetailsProps) {
  const { t } = useTranslation();
  const fullName = [firstName, lastName].filter((item) => item).join(` `);
  return (
    <Card variant="table">
      <CardHeader>{t(`represented_user`)}</CardHeader>
      <CardBody>
        <VStack alignItems="flex-start" gap={2}>
          {fullName && <Text fontWeight="500">{fullName}</Text>}
          {email && <CopyToClipboard value={email} />}
        </VStack>
      </CardBody>
    </Card>
  );
}

export function BidBuyerCardTable({
  buyer,
  representedUser,
  broker,
  placedByHiive,
}: BidBuyerCardTableProps) {
  const { t } = useTranslation();
  const showRepresentedUser = representedUser && !broker;
  const isBroker = broker?.id === buyer.id;

  return (
    <CardTable w="full" columns={showRepresentedUser ? 2 : 1}>
      <Card variant="table">
        <CardHeader>{t(`buyer_details`)}</CardHeader>
        <CardBody>
          <HStack gap={4} flexWrap="wrap">
            {isBroker ? (
              <UserDetails
                investorDescriptor={InvestorDescriptor.Broker}
                variant="default"
                {...broker}
              />
            ) : (
              <>
                {broker && (
                  <UserDetails
                    investorDescriptor={InvestorDescriptor.Broker}
                    variant="default"
                    {...broker}
                  />
                )}
                <UserDetails
                  variant={placedByHiive ? `hiiveUser` : `default`}
                  {...buyer}
                />
              </>
            )}
          </HStack>
        </CardBody>
      </Card>
      {showRepresentedUser && <RepresentedUserDetails {...representedUser} />}
    </CardTable>
  );
}
