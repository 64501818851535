import {
  DateTimeFormat,
  formatCurrencyCents,
  formatDate,
  formatShares,
} from "@/modules/NumeralFormat";
import { Status } from "@/modules/Status";
import { Box, Text } from "@chakra-ui/react";
import { Cell } from "@tanstack/react-table";
import { ListingStatus } from "@/features/Listing";
import { BidListingFragment } from "@/graphql";

type BidListingsTableCell = {
  cell: Cell<BidListingFragment, unknown>;
};

export function ListingIdTableCell({ cell }: BidListingsTableCell) {
  const { displayId } = cell.getContext().row.original;

  if (!displayId) {
    return <Status.Empty />;
  }

  return (
    <Box display="inline-block">
      <Text>{displayId}</Text>
    </Box>
  );
}

export function SharesPPSTableCell({ cell }: BidListingsTableCell) {
  const { numSharesAvailable, pricePerShare } = cell.getContext().row.original;
  if (!numSharesAvailable || !pricePerShare) {
    return <Status.Empty />;
  }

  return (
    <Box display="inline-block" maxW={{ base: 75, xl: 125 }}>
      <Text>{`${formatShares(numSharesAvailable)} @ ${formatCurrencyCents(
        pricePerShare,
      )}`}</Text>
    </Box>
  );
}

export function SellerEmailTableCell({ cell }: BidListingsTableCell) {
  const {
    seller: { email },
  } = cell.getContext().row.original;

  if (!email) {
    return <Status.Empty />;
  }

  return (
    <Box display="inline-block">
      <Text>{email}</Text>
    </Box>
  );
}

export function PlacedOnTableCell({ cell }: BidListingsTableCell) {
  const { insertedAt } = cell.getContext().row.original;

  if (!insertedAt) {
    return <Status.Empty />;
  }

  return (
    <Box display="inline-block" maxW="120px">
      {formatDate(insertedAt, DateTimeFormat.fullDateWithMonthShort)}
    </Box>
  );
}

export function StateTableCell({ cell }: BidListingsTableCell) {
  const { state } = cell.getContext().row.original;

  if (!state) {
    return <Status.Empty />;
  }

  return (
    <Box display="inline-block">
      <ListingStatus state={state} />
    </Box>
  );
}
