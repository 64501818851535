import { bidStateNames } from "@/features/Bid";
import { BidFragment, BidRepresentedUserFragment } from "@/graphql";
import {
  formatCurrency,
  formatCurrencyCents,
  formatShares,
  formatToLocalTimezone,
} from "@/modules/NumeralFormat";
import i18next from "i18next";

export type FormattedBid = {
  formattedNumShares: string;
  formattedPricePerShare: string;
  formattedTotal: string;
  formattedRepresentedUserName: string | null;
  formattedBuyerInvestorType: string;
  formattedSolicited: string;
  formattedAffiliated: string;
  formattedPlacedAt: string;
  formattedExpires: string;
  formattedStatus: string | null;
  placedByHiive: boolean;
};

const formatBidRepresentedUser = ({
  firstName,
  lastName,
}: BidRepresentedUserFragment) => `${firstName} ${lastName}`;

export const mapToBid = (bid: BidFragment): FormattedBid => {
  const {
    numShares,
    pricePerShare,
    buyer: { investorType, isHiiveUser },
    representedUser,
    solicited,
    affiliate,
    placedAt,
    expireAt,
    state,
    broker,
  } = bid;

  const formattedNumShares = formatShares(numShares);
  const formattedPricePerShare = formatCurrencyCents(pricePerShare);
  const formattedTotal = formatCurrency((pricePerShare / 100) * numShares);
  const formattedRepresentedUserName = representedUser
    ? formatBidRepresentedUser(representedUser)
    : null;
  const formattedBuyerInvestorType = investorType || ``;
  const formattedSolicited = solicited
    ? i18next.t(`solicited`)
    : i18next.t(`unsolicited`);
  const formattedAffiliated = affiliate ? i18next.t(`yes`) : i18next.t(`no`);
  const formattedPlacedAt = formatToLocalTimezone(placedAt);
  const formattedExpires = formatToLocalTimezone(expireAt);
  const formattedStatus = state ? bidStateNames[state] : null;
  const placedByHiive = isHiiveUser || !!broker;

  return {
    formattedNumShares,
    formattedPricePerShare,
    formattedTotal,
    formattedRepresentedUserName,
    formattedBuyerInvestorType,
    formattedSolicited,
    formattedAffiliated,
    formattedPlacedAt,
    formattedExpires,
    formattedStatus,
    placedByHiive,
  };
};
