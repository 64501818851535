import { VStack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ListingsTableProvider } from "@/features/Listings";
import ListingsTable, { ListingsUrlParams } from "./ListingsTable";

interface ListingsPageProps {
  query: ListingsUrlParams;
}

export default function ListingsPage({ query }: ListingsPageProps) {
  const { t } = useTranslation();

  return (
    <ListingsTableProvider query={query}>
      <VStack p={8} gap={0} alignItems="flex-start">
        <Text mb={4} textStyle="colfax-22-medium">
          {t(`listings`)}
        </Text>
        <ListingsTable />
      </VStack>
    </ListingsTableProvider>
  );
}
