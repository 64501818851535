import { ListingDetailsContext } from "@/features/Listing";
import { FeeBreakdown } from "@/modules/FeeBreakdown";
import { useContext } from "react";

export function ListingFeeBreakdownCardTable() {
  const { listing } = useContext(ListingDetailsContext);
  const {
    pricePerShare,
    numSharesAvailable,
    feeDiscountApplications,
    commission,
  } = listing || {};

  return (
    <FeeBreakdown
      numSharesActual={numSharesAvailable || 0}
      pricePerShare={pricePerShare || 0}
      feeDiscountApplications={feeDiscountApplications}
      commission={commission}
    />
  );
}
