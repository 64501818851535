import { ListingDetailsContext } from "@/features/Listing";
import {
  CompanyTableCell,
  DatabaseIDTableCell,
  SharesPPSTableCell,
  SigningProcedureTableCell,
  UpdatedAtTableCell,
  TransactionStateTableCell,
  TransactionsTableColumnType,
  TransactionsTableDataType,
} from "@/features/Transactions";
import {
  BidTransactionFragment,
  TransactionsTableNodeFragment,
} from "@/graphql";
import { Table, TableColumns } from "@/modules/Table";
import {
  Card,
  CardBody,
  CardHeader,
  Menu,
  MenuGroup,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { Row } from "@tanstack/react-table";
import i18next, { t } from "i18next";
import { useRouter } from "next/router";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

const EMPTY_STATE_TRANSACTION = [{ bid: {} }];

type ListingTransactionsTableColumn = Omit<
  TransactionsTableColumnType,
  "buyerEmail" | "sellerEmail"
>;
type ListingTransactionsTableData = TransactionsTableDataType &
  Partial<Pick<TransactionsTableNodeFragment, "nextStates" | "transferMethod">>;

function RowActions({ original }: Row<ListingTransactionsTableData>) {
  const router = useRouter();

  const oClick = () => router.push(`/transactions/${original.id}`);

  return (
    <Menu>
      <Table.ActionButton />
      <MenuList>
        <MenuGroup>
          <MenuItem onClick={oClick}>{t(`view_transaction`)}</MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
}

const columns: TableColumns<
  ListingTransactionsTableColumn,
  ListingTransactionsTableData
> = {
  companyName: {
    header: () => i18next.t(`company`),
    cell: ({ cell }) => <CompanyTableCell cell={cell} />,
    enableSorting: false,
  },
  updatedAt: {
    header: () => i18next.t(`last_updated`),
    cell: ({ cell }) => <UpdatedAtTableCell cell={cell} />,
    enableSorting: false,
  },
  id: {
    header: () => i18next.t(`database_id`),
    cell: ({ cell }) => <DatabaseIDTableCell cell={cell} />,
    enableSorting: false,
  },
  signingProcedure: {
    header: () => i18next.t(`signing_procedure`),
    cell: ({ cell }) => <SigningProcedureTableCell cell={cell} />,
    enableSorting: false,
  },
  sharesPPS: {
    header: () => i18next.t(`price_per_share_abbr`),
    cell: ({ cell }) => <SharesPPSTableCell cell={cell} />,
    enableSorting: false,
  },
  state: {
    header: () => i18next.t(`state`),
    cell: ({ cell }) => <TransactionStateTableCell cell={cell} />,
    enableSorting: false,
  },
};

const sortTransactions = (transactions: BidTransactionFragment[]) =>
  transactions.toSorted(
    ({ updatedAt: leftUpdatedAt }, { updatedAt: rightUpdatedAt }) =>
      new Date(rightUpdatedAt).getTime() - new Date(leftUpdatedAt).getTime(),
  );

export function ListingTransactionsCard() {
  const { t } = useTranslation();
  const { push } = useRouter();
  const { listing } = useContext(ListingDetailsContext);

  const transactionsLength = listing?.transactions?.length ?? 0;

  const onRowClick = useCallback(
    (row: Row<ListingTransactionsTableData>) => {
      const { id } = row.original;
      if (id) {
        push(`/transactions/${id}`);
      }
    },
    [push],
  );

  const data = (
    listing && transactionsLength > 0
      ? sortTransactions(listing.transactions)
      : EMPTY_STATE_TRANSACTION
  ) as ListingTransactionsTableData[];

  const additionalProps =
    transactionsLength > 0
      ? {
          onRowClick,
          renderRowActions: RowActions,
        }
      : {};

  return (
    <Card w="full">
      <CardHeader textStyle="colfax-22-medium">
        {t(`transactions_on_this_listing`)}
      </CardHeader>
      <CardBody>
        <Table<ListingTransactionsTableColumn[], ListingTransactionsTableData[]>
          columns={columns}
          data={data}
          borderColor="grey.100"
          borderStyle="solid"
          borderBottomWidth={1}
          borderLeftWidth={1}
          borderRightWidth={1}
          {...additionalProps}
        />
      </CardBody>
    </Card>
  );
}
