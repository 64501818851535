import {
  BidState,
  CompanyStatus,
  Maybe,
  useBidTableRowActionsUpdateBidSolicitedMutation,
  useBidTableRowActionsWithdrawBidMutation,
} from "@/graphql";
import { ConfirmModal } from "@/modules/Modal";
import { Table } from "@/modules/Table";
import { useCustomToast } from "@/modules/Toast";
import {
  Box,
  Link,
  Menu,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { ArrowSquareOut, Circle } from "@phosphor-icons/react";
import { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { match } from "ts-pattern";

const STATUS_ICON_SIZE = 10;

type BidMenuItemProps = {
  bidId: string;
};

type BidTableRowActionsProps = {
  bidId: string;
  solicited: boolean;
  state?: Maybe<BidState>;
  companyState?: Maybe<CompanyStatus>;
  showActionButtonTitle?: boolean;
};

function SolicitBidMenuItem({ bidId }: BidMenuItemProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [, updateBidSolicited] =
    useBidTableRowActionsUpdateBidSolicitedMutation();
  const { errorToast, successToast } = useCustomToast();

  return (
    <>
      <MenuGroup>
        <MenuItem
          icon={
            <Box
              as={Circle}
              size={STATUS_ICON_SIZE}
              color="inactive"
              weight="fill"
            />
          }
          onClick={() => setOpen(true)}
        >
          {t(`change_to_solicited`)}
        </MenuItem>
      </MenuGroup>
      <ConfirmModal
        title={t(`confirm_change_to_solicited`)}
        body={
          <Text>
            <Trans
              i18nKey="confirm_change_to_solicited_message"
              components={{ bold: <strong /> }}
            />
          </Text>
        }
        isOpen={open}
        onClose={() => setOpen(false)}
        onConfirm={async () => {
          const { data } = await updateBidSolicited({ bidId, solicited: true });
          if (data?.updateBidSolicited?.bid?.id) {
            successToast();
          } else {
            errorToast();
          }
          setOpen(false);
        }}
      />
    </>
  );
}

function UnsolicitBidMenuItem({ bidId }: BidMenuItemProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [, updateBidSolicited] =
    useBidTableRowActionsUpdateBidSolicitedMutation();
  const { errorToast, successToast } = useCustomToast();

  return (
    <>
      <MenuGroup>
        <MenuItem
          icon={
            <Box
              as={Circle}
              size={STATUS_ICON_SIZE}
              color="pending"
              weight="fill"
            />
          }
          onClick={() => setOpen(true)}
        >
          {t(`change_to_unsolicited`)}
        </MenuItem>
      </MenuGroup>
      <ConfirmModal
        title={t(`confirm_change_to_unsolicited`)}
        body={
          <Text>
            <Trans
              i18nKey="confirm_change_to_unsolicited_message"
              components={{ bold: <strong /> }}
            />
          </Text>
        }
        isOpen={open}
        onClose={() => setOpen(false)}
        onConfirm={async () => {
          const { data } = await updateBidSolicited({
            bidId,
            solicited: false,
          });
          if (data?.updateBidSolicited?.bid?.id) {
            successToast();
          } else {
            errorToast();
          }
          setOpen(false);
        }}
      />
    </>
  );
}

function WithdrawBidMenuItem({ bidId }: BidMenuItemProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [, withdrawBid] = useBidTableRowActionsWithdrawBidMutation();
  const { errorToast, successToast } = useCustomToast();

  return (
    <>
      <MenuItem color="archived" onClick={() => setOpen(true)}>
        {t(`withdraw_bid`)}
      </MenuItem>
      <ConfirmModal
        title={t(`confirm_withdraw_bid`)}
        body={
          <Text>
            <Trans
              i18nKey="confirm_change_withdraw_bid_message"
              components={{ bold: <strong /> }}
            />
          </Text>
        }
        isOpen={open}
        onClose={() => setOpen(false)}
        onConfirm={async () => {
          setOpen(false);
          const { data } = await withdrawBid({ bidId });
          if (data?.withdrawBid?.bid?.id) {
            successToast();
          } else {
            errorToast();
          }
        }}
      />
    </>
  );
}

export function BidTableRowActions({
  bidId,
  solicited,
  state,
  companyState = CompanyStatus.Listed,
  showActionButtonTitle,
}: BidTableRowActionsProps) {
  const { t } = useTranslation();
  const solicitedMenuItem = useMemo(
    () =>
      match(solicited)
        .with(true, () => <UnsolicitBidMenuItem bidId={bidId} />)
        .with(false, () => <SolicitBidMenuItem bidId={bidId} />)
        .exhaustive(),
    [bidId, solicited],
  );

  const showWithdrawBid =
    state &&
    [BidState.Active, BidState.Countered].includes(state) &&
    companyState === CompanyStatus.Listed;

  return (
    <Menu>
      <Table.ActionButton showTitle={showActionButtonTitle} />
      <MenuList>
        {solicitedMenuItem}
        <MenuDivider />
        <MenuGroup>
          {showWithdrawBid && (
            <>
              <WithdrawBidMenuItem bidId={bidId} />
              <MenuDivider />
            </>
          )}
          <MenuItem
            as={Link}
            href={`${process.env.NEXT_PUBLIC_HIIVE_URL}/listings/bids/${bidId}`}
            fontWeight={400}
            isExternal
          >
            {t(`open_bid_page`)}
            <Box as={ArrowSquareOut} size={16} ml={1} />
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
}
