import {
  ListingPageFragment,
  ListingPageQuery,
  useListingPageQuery,
} from "@/graphql";
import { ReactNode, createContext, useCallback, useMemo } from "react";
import { CombinedError } from "urql";

type FeeDiscountApplicationsType = NonNullable<
  ListingPageFragment["feeDiscountApplications"]
>;

type BidType = ListingPageFragment["bids"][number];

export type ListingDetailsType = ListingPageFragment & {
  activeFeeDiscountApplications: FeeDiscountApplicationsType;
  transactions: NonNullable<BidType["transaction"]>[];
};

interface ListingDetailsContext {
  fetching?: boolean;
  error?: CombinedError;
  listing?: ListingDetailsType;
  refetch?: () => void;
}

const Context = createContext<ListingDetailsContext>({});
const { Provider } = Context;

function mapListingData(data?: ListingPageQuery) {
  return {
    ...data?.listing,
    activeFeeDiscountApplications: data?.listing?.feeDiscountApplications || [],
    transactions: data?.listing?.bids
      ?.filter(({ transaction }) => transaction)
      .map(({ transaction }) => transaction),
  } as ListingDetailsType;
}

interface ListingDetailsProviderProps {
  id: string;
  children: ReactNode;
}

function ListingDetailsProvider({ id, children }: ListingDetailsProviderProps) {
  const [{ fetching, error, data }, executeQuery] = useListingPageQuery({
    variables: {
      id,
    },
    requestPolicy: `cache-and-network`,
  });
  const refetch = useCallback(() => {
    executeQuery({ requestPolicy: `network-only` });
  }, [executeQuery]);

  const listing = useMemo(() => mapListingData(data), [data]);

  return (
    <Provider value={{ fetching, error, listing, refetch }}>
      {children}
    </Provider>
  );
}

export { Context, ListingDetailsProvider };
