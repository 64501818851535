import {
  CompanyStatus,
  ListingState,
  Maybe,
  useListingsTableRowActionsMarkListingSolicitedMutation,
  useListingsTableRowActionsMarkListingUnsolicitedMutation,
  useListingsTableRowActionsWithdrawListingMutation,
} from "@/graphql";
import { ConfirmModal } from "@/modules/Modal";
import { Table } from "@/modules/Table";
import { useCustomToast } from "@/modules/Toast";
import {
  Box,
  Link,
  Menu,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { ArrowSquareOut, Circle } from "@phosphor-icons/react";
import { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { match } from "ts-pattern";

const STATUS_ICON_SIZE = 7;

interface ListingMenuItemProps {
  listingId: string;
}

function SolicitListingMenuItem({ listingId }: ListingMenuItemProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [, solicitListing] =
    useListingsTableRowActionsMarkListingSolicitedMutation();
  const { errorToast, successToast } = useCustomToast();

  return (
    <>
      <MenuGroup>
        <MenuItem
          icon={
            <Box
              as={Circle}
              size={STATUS_ICON_SIZE}
              color="pending"
              weight="fill"
            />
          }
          onClick={() => setOpen(true)}
        >
          {t(`change_to_solicited`)}
        </MenuItem>
      </MenuGroup>
      <ConfirmModal
        title={t(`confirm_change_to_solicited`)}
        body={
          <Text>
            <Trans
              i18nKey="confirm_change_to_solicited_message"
              components={{ bold: <strong /> }}
            />
          </Text>
        }
        isOpen={open}
        onClose={() => setOpen(false)}
        onConfirm={async () => {
          setOpen(false);
          const { data } = await solicitListing({ listingId });
          if (data?.markListingSolicited?.listing?.id) {
            successToast();
          } else {
            errorToast();
          }
        }}
      />
    </>
  );
}

function UnsolicitListingMenuItem({ listingId }: ListingMenuItemProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [, unsolicitListing] =
    useListingsTableRowActionsMarkListingUnsolicitedMutation();
  const { errorToast, successToast } = useCustomToast();

  return (
    <>
      <MenuGroup>
        <MenuItem
          icon={
            <Box
              as={Circle}
              size={STATUS_ICON_SIZE}
              color="pending"
              weight="fill"
            />
          }
          onClick={() => setOpen(true)}
        >
          {t(`change_to_unsolicited`)}
        </MenuItem>
      </MenuGroup>
      <ConfirmModal
        title={t(`confirm_change_to_unsolicited`)}
        body={
          <Text>
            <Trans
              i18nKey="confirm_change_to_unsolicited_message"
              components={{ bold: <strong /> }}
            />
          </Text>
        }
        isOpen={open}
        onClose={() => setOpen(false)}
        onConfirm={async () => {
          setOpen(false);
          const { data } = await unsolicitListing({ listingId });
          if (data?.markListingUnsolicited?.listing?.id) {
            successToast();
          } else {
            errorToast();
          }
        }}
      />
    </>
  );
}

function WithdrawListingMenuItem({ listingId }: ListingMenuItemProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [, withdrawListing] =
    useListingsTableRowActionsWithdrawListingMutation();
  const { errorToast, successToast } = useCustomToast();

  return (
    <>
      <MenuItem color="archived" onClick={() => setOpen(true)}>
        {t(`withdrawListing`)}
      </MenuItem>
      <ConfirmModal
        title={t(`confirm_withdraw_listing`)}
        body={
          <Text>
            <Trans
              i18nKey="confirm_change_withdraw_listing_message"
              components={{ bold: <strong /> }}
            />
          </Text>
        }
        isOpen={open}
        onClose={() => setOpen(false)}
        onConfirm={async () => {
          setOpen(false);
          const { data } = await withdrawListing({ listingId });
          if (data?.withdrawListing?.listing?.id) {
            successToast();
          } else {
            errorToast();
          }
        }}
      />
    </>
  );
}

interface ListingsTableRowActionsProps {
  solicited?: boolean;
  listingId: string;
  state: string;
  companyState?: Maybe<CompanyStatus>;
  showActionButtonTitle?: boolean;
}

export default function ListingsTableRowActions({
  listingId,
  solicited,
  state,
  companyState = CompanyStatus.Listed,
  showActionButtonTitle,
}: ListingsTableRowActionsProps) {
  const { t } = useTranslation();
  const action = useMemo(
    () =>
      match(solicited as boolean)
        .with(true, () => <UnsolicitListingMenuItem listingId={listingId} />)
        .with(false, () => <SolicitListingMenuItem listingId={listingId} />)
        .exhaustive(),
    [listingId, solicited],
  );

  const showWithdrawListing =
    state === ListingState.Open && companyState === CompanyStatus.Listed;

  return (
    <Menu>
      <Table.ActionButton showTitle={showActionButtonTitle} />
      <MenuList>
        {typeof solicited === `boolean` && (
          <>
            {action}
            <MenuDivider />
          </>
        )}
        <MenuGroup>
          {showWithdrawListing && (
            <>
              <WithdrawListingMenuItem listingId={listingId} />
              <MenuDivider />
            </>
          )}
          <MenuItem
            as={Link}
            isExternal
            href={`${process.env.NEXT_PUBLIC_HIIVE_URL}/listings/${listingId}`}
            fontWeight={400}
          >
            {t(`open_listing_page`)}
            <Box as={ArrowSquareOut} size={16} ml={1} />
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
}
