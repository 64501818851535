import { ListingDetailsContext } from "@/features/Listing";
import { ListingPageFragment, ListingState } from "@/graphql";
import { formatToLocalTimezone } from "@/modules/NumeralFormat";
import { Status } from "@/modules/Status";
import {
  Card,
  CardBody,
  CardHeader,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ReactNode, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ListingIgnoreInPricingToggle } from "./ListingIgnoreInPricingToggle";

function ListingStatusItem({
  listing,
  value,
}: {
  listing: ListingPageFragment | undefined;
  value: (listing: ListingPageFragment) => ReactNode | null;
}) {
  if (!listing) {
    return <Status.Empty />;
  }

  return <Text>{value(listing) ?? <Status.Empty />}</Text>;
}

export function ListingStatusCard() {
  const { t } = useTranslation();
  const { listing } = useContext(ListingDetailsContext);

  const mappedData = useMemo(
    () => [
      {
        label: t(`placed`),
        value: ({ placedAt }: ListingPageFragment) =>
          placedAt ? formatToLocalTimezone(placedAt) : null,
      },
      {
        label: t(`expires`),
        value: ({ expireAt }: ListingPageFragment) =>
          expireAt ? formatToLocalTimezone(expireAt) : null,
      },
      {
        label: t(`status`),
        value: ({ state }: ListingPageFragment) =>
          state
            ? (t(
                {
                  [ListingState.InReview]: `listing_submitted_to_hiive_status`,
                  [ListingState.Open]: `listing_live_status`,
                  [ListingState.ConditionallySold]: `listing_conditionally_sold_status`,
                  [ListingState.Closed]: `listing_closed_status`,
                  [ListingState.Withdrawn]: `listing_closed_status`,
                  [ListingState.Expired]: null,
                  [ListingState.Rejected]: null,
                }[state] as unknown as TemplateStringsArray,
              ) as string)
            : null,
      },
      {
        label: t(`show_in_price_graph`),
        value: (listing: ListingPageFragment) => (
          <ListingIgnoreInPricingToggle listing={listing} />
        ),
      },
    ],
    [t],
  );

  return (
    <Card w="full">
      <CardHeader textStyle="colfax-22-medium">
        {t(`dates_and_status`)}
      </CardHeader>
      <CardBody>
        <VStack gap={4}>
          {mappedData.map(({ label, value }) => (
            <HStack justifyContent="space-between" w="full" key={label}>
              <Text
                textStyle="colfax-14-medium-uncased"
                textTransform="uppercase"
              >
                {label}
              </Text>
              <ListingStatusItem listing={listing} value={value} />
            </HStack>
          ))}
        </VStack>
      </CardBody>
    </Card>
  );
}
