import { ReactNode, createContext, useContext } from "react";
import { UseQueryState } from "urql";

import { getDefaultTableProviderVariables } from "@/constants";
import {
  BidSort,
  BidSortField,
  BidsTableQuery,
  BidsTableQueryVariables,
  SortDirection,
  useBidsTableQuery,
} from "@/graphql";
import {
  SimpleTableUrlParams,
  TableContext,
  TableProvider,
} from "@/modules/SimpleTable";

interface BidsTableProviderProps {
  children: ReactNode;
  query: SimpleTableUrlParams & {
    status?: string;
  };
}

interface ChildProps {
  children: ReactNode;
}

const Context = createContext<{
  data: UseQueryState<BidsTableQuery, BidsTableQueryVariables> | undefined;
}>({ data: undefined });
const { Provider } = Context;

const DEFAULT_VARIABLES: BidsTableQueryVariables & { page: number } =
  getDefaultTableProviderVariables({
    sortBy: {
      field: BidSortField.InsertedAt,
      direction: SortDirection.Desc,
    },
  });

function BidsTableProviderContent({ children }: ChildProps) {
  const { variables } = useContext(TableContext);

  const [data] = useBidsTableQuery({
    variables: {
      ...variables,
      filterBy: {
        ...variables.filterBy,
      },
      sortBy: variables.sortBy as BidSort,
    },
    requestPolicy: `cache-and-network`,
  });

  return <Provider value={{ data }}>{children}</Provider>;
}

function BidsTableProvider({ children, query }: BidsTableProviderProps) {
  return (
    <TableProvider
      query={query}
      defaultVariables={DEFAULT_VARIABLES}
      sortFields={Object.values(BidSortField)}
    >
      <BidsTableProviderContent>{children}</BidsTableProviderContent>
    </TableProvider>
  );
}

export { Context as BidsTableContext, BidsTableProvider };
