import { ListingsTableQuery, ListingsTableQueryVariables } from "@/graphql";
import { DateTimeFormat, formatDate } from "@/modules/NumeralFormat";

import { ListingsTableDataType } from "@/features/Listings";
import currency from "currency.js";
import { UseQueryState } from "urql";

const DEFAULT = { listings: [], totalCount: 0, pageInfo: undefined };

export const mapToTable = (
  responseData:
    | UseQueryState<ListingsTableQuery, ListingsTableQueryVariables>
    | undefined,
) => {
  if (!responseData || !responseData.data || !responseData.data.listings) {
    return {
      ...DEFAULT,
      error: responseData?.error,
      fetching: responseData?.fetching,
    };
  }

  const listings: ListingsTableDataType[] =
    responseData.data.listings.edges.map(({ node }) => {
      const {
        id,
        displayId,
        company,
        numSharesOriginal,
        pricePerShare,
        seller,
        broker,
        solicited,
        insertedAt,
        updatedAt,
        state,
      } = node;

      const formattedNumShares = currency(numSharesOriginal.toString(), {
        separator: `,`,
        precision: 0,
        symbol: ``,
      }).format();

      const formattedPricePerShare = currency(
        ((pricePerShare || 0) / 100).toString(),
        {
          separator: `,`,
          precision: 2,
          symbol: `$`,
        },
      ).format();

      return {
        id,
        displayId,
        companyName: company.name,
        securitySpecialistFullName: company.securitySpecialist?.user.name,
        shares: `${formattedNumShares} @ ${formattedPricePerShare}`,
        sellerEmail: seller.email,
        brokerEmail: broker?.email,
        solicited,
        insertedAt: formatDate(
          insertedAt,
          DateTimeFormat.fullDateWithMonthShort,
        ),
        updatedAt: formatDate(updatedAt, DateTimeFormat.fullDateWithMonthShort),
        state,
      };
    });

  return {
    listings,
    totalCount: responseData.data.listings.totalCount,
    pageInfo: responseData.data.listings.pageInfo,
    error: responseData.error,
    loading: responseData.fetching,
  };
};
