import { Status } from "@/modules/Status";
import {
  Card,
  CardBody,
  CardHeader,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { BidIgnoreInPricingToggle } from "./BidIgnoreInPricingToggle";

type BidStatusCardProps = {
  id: string;
  displayId: string;
  ignoreInPricing: boolean;
  formattedPlacedAt: string;
  formattedExpires: string;
  formattedStatus: string;
};

const MAPPED_TABLE_DATA = [
  {
    label: i18next.t(`placed`),
    value: ({ formattedPlacedAt }: BidStatusCardProps) => formattedPlacedAt,
  },
  {
    label: i18next.t(`expires`),
    value: ({ formattedExpires }: BidStatusCardProps) => formattedExpires,
  },
  {
    label: i18next.t(`status`),
    value: ({ formattedStatus }: BidStatusCardProps) => formattedStatus,
  },
  {
    label: i18next.t(`show_in_price_graph`),
    value: ({ id, displayId, ignoreInPricing }: BidStatusCardProps) => (
      <BidIgnoreInPricingToggle
        id={id}
        displayId={displayId}
        ignoreInPricing={ignoreInPricing}
      />
    ),
  },
];

export function BidStatusCard(props: BidStatusCardProps) {
  const { t } = useTranslation();

  return (
    <Card w="full">
      <CardHeader textStyle="colfax-22-medium">
        {t(`dates_and_status`)}
      </CardHeader>
      <CardBody>
        <VStack gap={4}>
          {MAPPED_TABLE_DATA.map(({ label, value }) => (
            <HStack justifyContent="space-between" w="full" key={label}>
              <Text
                textStyle="colfax-14-medium-uncased"
                textTransform="uppercase"
              >
                {label}
              </Text>
              <Text>{value(props) || <Status.Empty />}</Text>
            </HStack>
          ))}
        </VStack>
      </CardBody>
    </Card>
  );
}
