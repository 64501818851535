import { BidData, getBidStateIndicatorColor } from "@/features/Bid";
import { CompanyInfoClipboard } from "@/features/Company";
import { EntityDetailsCardHeader } from "@/modules/EntityDetailsCardHeader";
import { Card, CardBody, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BidPriceCardTable } from "./BidPricingCardTable";
import { BidDetailsTable } from "./BidDetailsTable";
import { BidBuyerCardTable } from "./BidBuyerCardTable";
import { BidTableRowActions } from "./BidTableRowActions";

type BidCardProps = {
  bid: BidData;
};

export function BidCard({ bid }: BidCardProps) {
  const { t } = useTranslation();
  const [
    {
      id,
      displayId,
      state,
      company,
      solicited,
      affiliate,
      buyer,
      broker,
      representedUser,
    },
    {
      formattedNumShares,
      formattedPricePerShare,
      formattedTotal,
      formattedStatus,
      placedByHiive,
    },
  ] = bid;
  const { status } = company;
  const statusIndicatorColor = state ? getBidStateIndicatorColor(state) : ``;

  return (
    <Card w="full">
      <EntityDetailsCardHeader
        header={`${t(`bid`)} ${displayId}`}
        status={formattedStatus || ``}
        statusIndicatorColor={statusIndicatorColor}
      >
        <BidTableRowActions
          bidId={id}
          solicited={solicited ?? false}
          state={state}
          companyState={status}
          showActionButtonTitle
        />
      </EntityDetailsCardHeader>
      <CardBody>
        <CompanyInfoClipboard
          logoUrl={company.logoUrl}
          companyName={company.name}
          copyToClipboardItems={[{ label: t(`database_id`), value: id }]}
        />
        <VStack rowGap={6} my={6} alignItems="baseline">
          <BidPriceCardTable
            formattedNumShares={formattedNumShares}
            formattedPricePerShare={formattedPricePerShare}
            formattedTotal={formattedTotal}
          />
          <BidBuyerCardTable
            buyer={buyer}
            broker={broker}
            representedUser={representedUser}
            placedByHiive={placedByHiive}
          />
          <BidDetailsTable
            solicited={solicited ?? false}
            affiliated={affiliate ?? false}
            placedByHiive={placedByHiive}
          />
        </VStack>
      </CardBody>
    </Card>
  );
}
