import { ListingDetailsContext } from "@/features/Listing";
import {
  InvestorDescriptor,
  ListingPageFragment,
  MakeOptional,
} from "@/graphql";
import { CardTable } from "@/modules/CardTable";
import { CopyToClipboard } from "@/modules/CopyToClipboard";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Tag,
  TagProps,
  Text,
  VStack,
} from "@chakra-ui/react";
import { CaretRight } from "@phosphor-icons/react";
import { capitalCase } from "change-case";
import i18next from "i18next";
import Link from "next/link";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

type UserDetailsType = MakeOptional<
  Omit<ListingPageFragment["seller"], "__typename">,
  | "id"
  | "name"
  | "isHiiveUser"
  | "investorDescriptor"
  | "feeDiscountApplications"
>;

const TAG_VARIANT: Record<
  string,
  { variant: TagProps["variant"]; text?: string }
> = {
  hiiveUser: { variant: `lightBlue`, text: i18next.t(`hiive`) },
  default: { variant: `grey` },
};

function UserDetails({
  id,
  name,
  email,
  investorDescriptor,
  isHiiveUser,
}: UserDetailsType) {
  const { t } = useTranslation();
  const tagVariant =
    isHiiveUser && investorDescriptor !== InvestorDescriptor.Broker
      ? TAG_VARIANT.hiiveUser
      : TAG_VARIANT.default;
  return (
    <Box
      backgroundColor="grey.25"
      borderColor="grey.50"
      borderWidth={1}
      borderRadius={6}
      p={3}
      maxW={400}
      flex={1}
    >
      <VStack alignItems="flex-start" gap={1} marginBottom={4}>
        {investorDescriptor && (
          <Tag size="sm" variant={tagVariant.variant}>
            {tagVariant.text || capitalCase(investorDescriptor)}
          </Tag>
        )}
        <Text fontWeight="500">{name}</Text>
        {email && <CopyToClipboard value={email} />}
      </VStack>
      <Link href={`/users/${id}`}>
        <Button variant="outline" size="md">
          <HStack>
            <Text textStyle="colfax-12-medium">{t`full_details`}</Text>
            <CaretRight size={14} />
          </HStack>
        </Button>
      </Link>
    </Box>
  );
}

function RepresentedUserDetails({
  email,
  firstName,
  lastName,
}: NonNullable<ListingPageFragment["representedUser"]>) {
  const { t } = useTranslation();
  const fullName = [firstName, lastName].filter((item) => item).join(` `);
  return (
    <Card variant="table">
      <CardHeader>{t(`represented_user`)}</CardHeader>
      <CardBody>
        <VStack alignItems="flex-start" gap={2}>
          {fullName && <Text fontWeight="500">{fullName}</Text>}
          {email && <CopyToClipboard value={email} />}
        </VStack>
      </CardBody>
    </Card>
  );
}

export function ListingSellerCardTable() {
  const { t } = useTranslation();
  const { listing } = useContext(ListingDetailsContext);
  const {
    seller: sellerRaw,
    broker: brokerRaw,
    representedUser,
  } = listing || {};
  const broker: UserDetailsType | null = brokerRaw
    ? {
        ...brokerRaw,
        investorDescriptor: InvestorDescriptor.Broker,
      }
    : null;
  const seller: UserDetailsType | null = sellerRaw
    ? {
        ...sellerRaw,
        isHiiveUser: sellerRaw?.isHiiveUser || !!broker,
      }
    : null;
  const userDetailItems =
    broker?.id === seller?.id ? [broker] : [broker, seller];

  const showRepresentedUser = representedUser && !broker;
  return (
    <CardTable w="full" columns={showRepresentedUser ? 2 : 1}>
      <Card variant="table">
        <CardHeader>{t(`seller_details`)}</CardHeader>
        <CardBody>
          <HStack gap={4} flexWrap="wrap">
            {userDetailItems
              .filter((item): item is UserDetailsType => !!item)
              .map((item) => (
                <UserDetails key={item.id} {...item} />
              ))}
          </HStack>
        </CardBody>
      </Card>
      {showRepresentedUser && <RepresentedUserDetails {...representedUser} />}
    </CardTable>
  );
}
