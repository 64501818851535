import { BidsTableQuery, BidsTableQueryVariables } from "@/graphql";
import {
  DateTimeFormat,
  formatCurrencyCents,
  formatDate,
  formatShares,
} from "@/modules/NumeralFormat";

import { BidsTableDataType } from "@/features/Bids";
import { UseQueryState } from "urql";

const DEFAULT = { bids: [], totalCount: 0, pageInfo: undefined };

export const mapToTable = (
  responseData:
    | UseQueryState<BidsTableQuery, BidsTableQueryVariables>
    | undefined,
) => {
  if (!responseData || !responseData.data || !responseData.data.bids) {
    return {
      ...DEFAULT,
      error: responseData?.error,
      fetching: responseData?.fetching,
    };
  }

  const bids: BidsTableDataType[] = responseData.data.bids.edges.map(
    ({ node }) => {
      const {
        company,
        numShares,
        pricePerShare,
        buyer,
        listing,
        insertedAt,
        placedAt,
        solicited,
        state,
      } = node;

      const formattedNumShares = formatShares(numShares);
      const formattedPricePerShare = formatCurrencyCents(pricePerShare);

      return {
        ...node,
        listingDisplayId: listing?.displayId,
        companyName: company.name,
        securitySpecialistName: company.securitySpecialist?.user.name,
        sharesPPS: `${formattedNumShares} @ ${formattedPricePerShare}`,
        buyerEmail: buyer?.email || null,
        insertedAt: formatDate(
          insertedAt,
          DateTimeFormat.fullDateWithMonthShort,
        ),
        lastUpdated: formatDate(
          placedAt,
          DateTimeFormat.fullDateWithMonthShort,
        ),
        state: state || null,
        solicited: solicited || false,
      };
    },
  );

  return {
    bids,
    totalCount: responseData.data.bids.totalCount,
    pageInfo: responseData.data.bids.pageInfo,
    error: responseData.error,
    loading: responseData.fetching,
  };
};
