import { VStack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BidsTableProvider, BidsTable, BidsUrlParams } from "@/features/Bids";

interface BidsPageProps {
  query: BidsUrlParams;
}

export function BidsPage({ query }: BidsPageProps) {
  const { t } = useTranslation();

  return (
    <BidsTableProvider query={query}>
      <VStack p={8} gap={0} alignItems="flex-start">
        <Text mb={4} textStyle="colfax-22-medium">
          {t(`bids`)}
        </Text>
        <BidsTable />
      </VStack>
    </BidsTableProvider>
  );
}
