import {
  DateTimeFormat,
  formatCurrencyCents,
  formatDate,
  formatShares,
} from "@/modules/NumeralFormat";
import { Status } from "@/modules/Status";
import { Box, Text } from "@chakra-ui/react";
import { Cell } from "@tanstack/react-table";
import { BidStatus } from "@/features/Bid";
import { BidsTableNodeFragment } from "@/graphql";

type BidsTableCell = {
  cell: Cell<BidsTableNodeFragment, unknown>;
};

export function BidIdTableCell({ cell }: BidsTableCell) {
  const { displayId } = cell.getContext().row.original;

  if (!displayId) {
    return <Status.Empty />;
  }

  return (
    <Box display="inline-block">
      <Text>{displayId}</Text>
    </Box>
  );
}

export function SharesPPSTableCell({ cell }: BidsTableCell) {
  const { numShares, pricePerShare } = cell.getContext().row.original;
  if (!numShares || !pricePerShare) {
    return <Status.Empty />;
  }

  return (
    <Box display="inline-block" maxW={{ base: 75, xl: 125 }}>
      <Text>{`${formatShares(numShares)} @ ${formatCurrencyCents(
        pricePerShare,
      )}`}</Text>
    </Box>
  );
}

export function BuyerEmailTableCell({ cell }: BidsTableCell) {
  const { buyer } = cell.getContext().row.original;

  if (!buyer?.email) {
    return <Status.Empty />;
  }

  const { email } = buyer;

  return (
    <Box display="inline-block">
      <Text>{email}</Text>
    </Box>
  );
}

export function PlacedOnTableCell({ cell }: BidsTableCell) {
  const { placedAt } = cell.getContext().row.original;

  if (!placedAt) {
    return <Status.Empty />;
  }

  return (
    <Box display="inline-block" maxW="120px">
      {formatDate(placedAt, DateTimeFormat.fullDateWithMonthShort)}
    </Box>
  );
}

export function StateTableCell({ cell }: BidsTableCell) {
  const { state } = cell.getContext().row.original;

  if (!state) {
    return <Status.Empty />;
  }

  return (
    <Box display="inline-block">
      <BidStatus state={state} />
    </Box>
  );
}
