import { BidState } from "@/graphql";
import { Status, StatusWithIndicatorProps } from "@/modules/Status";
import { t } from "i18next";
import { match } from "ts-pattern";

type BidStatusProps = Omit<StatusWithIndicatorProps, "text"> & {
  state: BidState;
};

export const bidStateNames: Record<BidState, string> = {
  [BidState.Accepted]: t(`accepted`),
  [BidState.Active]: t(`active`),
  [BidState.Closed]: t(`closed`),
  [BidState.Countered]: t(`countered`),
  [BidState.Expired]: t(`expired`),
  [BidState.InReview]: t(`in_review`),
  [BidState.Rejected]: t(`rejected`),
  [BidState.Superseded]: t(`superseded`),
  [BidState.Withdrawn]: t(`withdrawn`),
};

export function getBidStateIndicatorColor(state: BidState) {
  return match(state)
    .with(BidState.Accepted, () => `active`)
    .with(BidState.Active, () => `active`)
    .with(BidState.Closed, () => `inactive`)
    .with(BidState.Countered, () => `active`)
    .with(BidState.Expired, () => `archived`)
    .with(BidState.InReview, () => `pending`)
    .with(BidState.Rejected, () => `archived`)
    .with(BidState.Superseded, () => `archived`)
    .with(BidState.Withdrawn, () => `archived`)
    .otherwise(() => ``);
}

export function BidStatus({ state, ...restProps }: BidStatusProps) {
  return match(state)
    .with(BidState.Accepted, () => (
      <Status.Indicator
        text={bidStateNames[BidState.Accepted]}
        indicatorProps={{
          bg: `active`,
          ...restProps.indicatorProps,
        }}
        {...restProps}
      />
    ))
    .with(BidState.Active, () => (
      <Status.Indicator
        text={bidStateNames[BidState.Active]}
        indicatorProps={{
          bg: `active`,
          ...restProps.indicatorProps,
        }}
        {...restProps}
      />
    ))
    .with(BidState.Closed, () => (
      <Status.Indicator
        text={bidStateNames[BidState.Closed]}
        indicatorProps={{
          bg: `sky.600`,
          ...restProps.indicatorProps,
        }}
        {...restProps}
      />
    ))
    .with(BidState.Countered, () => (
      <Status.Indicator
        text={bidStateNames[BidState.Countered]}
        indicatorProps={{
          bg: `active`,
          ...restProps.indicatorProps,
        }}
        {...restProps}
      />
    ))
    .with(BidState.Expired, () => (
      <Status.Indicator
        text={bidStateNames[BidState.Expired]}
        indicatorProps={{
          bg: `archived`,
          ...restProps.indicatorProps,
        }}
        {...restProps}
      />
    ))
    .with(BidState.InReview, () => (
      <Status.Indicator
        text={bidStateNames[BidState.InReview]}
        indicatorProps={{
          bg: `pending`,
          ...restProps.indicatorProps,
        }}
        {...restProps}
      />
    ))
    .with(BidState.Rejected, () => (
      <Status.Indicator
        text={bidStateNames[BidState.Rejected]}
        indicatorProps={{
          bg: `archived`,
          ...restProps.indicatorProps,
        }}
        {...restProps}
      />
    ))
    .with(BidState.Superseded, () => (
      <Status.Indicator
        text={bidStateNames[BidState.Superseded]}
        indicatorProps={{
          bg: `archived`,
          ...restProps.indicatorProps,
        }}
        {...restProps}
      />
    ))
    .with(BidState.Withdrawn, () => (
      <Status.Indicator
        text={bidStateNames[BidState.Withdrawn]}
        indicatorProps={{
          bg: `archived`,
          ...restProps.indicatorProps,
        }}
        {...restProps}
      />
    ))
    .otherwise(() => ``);
}
