import { getListingStateIndicatorColor } from "@/features/Listing";
import { ListingsTableContext, mapToTable } from "@/features/Listings";
import { ListingsTableNodeFragment } from "@/graphql";
import { Input } from "@/modules/Form";
import {
  SimpleTable,
  SimpleTableQueryVariables,
  SimpleTableUrlParams,
  TableContext,
} from "@/modules/SimpleTable";
import { Status } from "@/modules/Status";
import {
  DEFAULT_TABLE_TOOLTIP_MAX_WIDTH,
  IndicatorColor,
  Table,
  TableColumns,
  TableTooltipCell,
} from "@/modules/Table";
import {
  Flex,
  HStack,
  Tab,
  VStack,
  Button,
  Spacer,
  Wrap,
} from "@chakra-ui/react";
import { Row } from "@tanstack/react-table";
import { capitalCase } from "change-case";
import i18next from "i18next";
import { useRouter } from "next/router";
import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import { RESET_PAGINATION_PARAMS } from "@/constants";
import { FilterTag } from "@/modules/FilterTag";
import ListingsSecuritySpecialistFilterButton from "./ListingsSecuritySpecialistFilterButton";
import ListingsTableRowActions from "./ListingsTableRowActions";

type ListingsTableColumnType = Pick<
  ListingsTableNodeFragment,
  "displayId" | "solicited" | "insertedAt" | "updatedAt" | "state"
> & {
  companyName: string;
  securitySpecialistFullName?: string | null;
  sellerEmail?: string | null;
  shares: string;
};

export type ListingsTableDataType = ListingsTableColumnType & {
  brokerEmail?: string | null;
};

export type ListingsUrlParams = SimpleTableUrlParams & {
  status?: string;
  page: string;
  before: string;
  after: string;
  first: string;
  last: string;
};

const columns: TableColumns<ListingsTableColumnType, ListingsTableDataType> = {
  displayId: {
    header: () => i18next.t(`listing_id`),
  },
  companyName: {
    header: () => i18next.t(`company`),
    cell: ({ cell, column: { id } }) => (
      <TableTooltipCell
        id={id}
        text={cell.getValue()}
        maxW={{ ...DEFAULT_TABLE_TOOLTIP_MAX_WIDTH, base: 125 }}
      />
    ),
  },
  securitySpecialistFullName: {
    header: () => i18next.t(`security_specialist`),
    cell: ({ cell, column: { id } }) => (
      <TableTooltipCell
        id={id}
        text={cell.getValue()}
        maxW={{ ...DEFAULT_TABLE_TOOLTIP_MAX_WIDTH, base: 155 }}
      />
    ),
  },
  shares: {
    header: () => i18next.t(`shares_pps`),
    enableSorting: false,
  },
  sellerEmail: {
    header: () => i18next.t(`seller_email`),
    cell: ({ cell, column: { id } }) => {
      const { displayId, brokerEmail, sellerEmail } = cell.row.original;
      const uniqueEmails = [...new Set([brokerEmail, sellerEmail])];
      return (
        <VStack alignItems="flex-start" gap={1}>
          {uniqueEmails.map((email) =>
            email ? (
              <TableTooltipCell
                key={`${displayId}-${email}`}
                id={id}
                text={email}
                maxW={{ ...DEFAULT_TABLE_TOOLTIP_MAX_WIDTH, base: 115 }}
                wrap
              />
            ) : null,
          )}
        </VStack>
      );
    },
  },
  insertedAt: {
    header: () => i18next.t(`created_on`),
  },
  updatedAt: {
    header: () => i18next.t(`last_updated`),
    enableSorting: false,
  },
  solicited: {
    header: () => i18next.t(`solicitation`),
    cell: ({ cell }) =>
      match<boolean>(cell.getValue())
        .with(true, () => (
          <Status.Indicator
            text={i18next.t(`solicited`)}
            indicatorProps={{ bg: IndicatorColor.LISTING_TYPE }}
          />
        ))
        .with(false, () => (
          <Status.Indicator
            text={i18next.t(`unsolicited`)}
            indicatorProps={{ bg: IndicatorColor.LISTING_TYPE }}
          />
        ))
        .exhaustive(),
    enableSorting: false,
  },
  state: {
    header: () => i18next.t(`state`),
    cell: ({ cell }) => {
      const state = cell.getValue();
      const indicatorColor = getListingStateIndicatorColor(state);

      return (
        <Status.Indicator
          text={capitalCase(state)}
          indicatorProps={{ bg: indicatorColor }}
        />
      );
    },
    enableSorting: false,
  },
};

function ListingsTableTabs() {
  const { t } = useTranslation();

  return (
    <Table.Tabs defaultIndex={0}>
      <Tab>{t(`all_listings`)}</Tab>
    </Table.Tabs>
  );
}

function ListingsTableSearch() {
  const { register } = useContext(TableContext);
  const { t } = useTranslation();

  return (
    <HStack alignItems="center">
      <Input.Search
        placeholder={t(`search_by_listing_id`)}
        w={430}
        {...register(`searchText`)}
      />
    </HStack>
  );
}

function ListingsTableHeader() {
  const { t } = useTranslation();
  const { data } = useContext(ListingsTableContext);
  const securitySpecialists = data?.data?.securitySpecialists || [];

  const { variables, setVariables, resetField } = useContext(TableContext);

  const onClearFilters = () => {
    setVariables((prevVariables) => ({
      ...prevVariables,
      filterBy: {
        ...prevVariables.filterBy,
        securitySpecialistIds: [],
      },
      ...RESET_PAGINATION_PARAMS,
    }));
    resetField(`securitySpecialistCheckbox`);
  };

  const selectedSecuritySpecialists =
    securitySpecialists?.filter(
      (securitySpecialist) =>
        variables.filterBy?.securitySpecialistIds?.includes(
          securitySpecialist.id,
        ),
    ) ?? [];

  const onRemoveSecuritySpecialistFilter = (
    omitSecuritySpecialistId: string,
  ) => {
    setVariables((prevVariables) => ({
      ...prevVariables,
      filterBy: {
        ...prevVariables.filterBy,
        securitySpecialistIds:
          prevVariables.filterBy?.securitySpecialistIds?.filter(
            (securitySpecialistId) =>
              securitySpecialistId !== omitSecuritySpecialistId,
          ),
      },
      ...RESET_PAGINATION_PARAMS,
    }));
    resetField(
      `securitySpecialistCheckbox.${omitSecuritySpecialistId}` as "securitySpecialistCheckbox.string",
    );
  };

  return (
    <Flex
      justifyContent="space-between"
      w="full"
      bg="white"
      p={4}
      borderTopRightRadius={8}
    >
      <VStack spacing={4} width="full">
        <HStack justifyContent="spaceBetween" width="full">
          <ListingsTableSearch />
          <Spacer />
          <ListingsSecuritySpecialistFilterButton
            securitySpecialists={securitySpecialists}
          />
        </HStack>
        {selectedSecuritySpecialists?.length > 0 && (
          <HStack w="full">
            <Wrap>
              {selectedSecuritySpecialists?.map((securitySpecialist) => (
                <FilterTag
                  key={securitySpecialist?.id}
                  name={`${securitySpecialist?.name}`}
                  onClick={() =>
                    onRemoveSecuritySpecialistFilter(securitySpecialist.id)
                  }
                />
              ))}
              <Button variant="link" onClick={onClearFilters}>
                {t(`clear_filters`)}
              </Button>
            </Wrap>
          </HStack>
        )}
      </VStack>
    </Flex>
  );
}

export default function ListingsTable() {
  const { push } = useRouter();
  const { page, searchText, setSort, setVariables, sort } =
    useContext(TableContext);
  const { data } = useContext(ListingsTableContext);

  const onRowClick = useCallback(
    (row: Row<Record<string, unknown>>) => {
      const { id } = row.original as ListingsTableNodeFragment;
      push(`/listings/${id}`);
    },
    [push],
  );

  const renderRowActions = useCallback(
    (row: Row<Record<string, unknown>>) => (
      <ListingsTableRowActions
        listingId={row.original.id as string}
        solicited={row.original.solicited as boolean}
        companyState={
          (row.original as ListingsTableNodeFragment).company?.status
        }
        state={row.original.state as string}
      />
    ),
    [],
  );

  const tableData = useMemo(() => mapToTable(data), [data]);

  if (!data) {
    return null;
  }

  return (
    <>
      <ListingsTableTabs />
      <ListingsTableHeader />
      <SimpleTable<
        SimpleTableQueryVariables,
        ListingsTableColumnType[],
        ListingsTableDataType[]
      >
        page={page}
        sort={sort}
        setSort={setSort}
        searchText={searchText}
        setVariables={setVariables}
        columns={columns}
        tableData={tableData.listings}
        pageInfo={tableData.pageInfo}
        totalCount={tableData.totalCount}
        renderRowActions={renderRowActions}
        error={data.error}
        loading={data.fetching}
        onRowClick={onRowClick}
      />
    </>
  );
}
