import { Status } from "@/modules/Status";
import { Box, CardHeader, HStack, Text } from "@chakra-ui/react";
import { ReactNode } from "react";

export default function EntityDetailsCardHeader({
  header,
  status,
  statusIndicatorColor,
  children,
}: {
  children?: ReactNode;
  header: string;
  status: string;
  statusIndicatorColor?: string;
}) {
  return (
    <CardHeader as={HStack} gap={4} alignItems="center">
      <Text textStyle="colfax-22-medium">{header}</Text>
      <Box flexGrow={1} />
      <HStack spacing={4}>
        <HStack>
          <Status.Indicator
            text={status}
            fontWeight={500}
            indicatorProps={{ bg: statusIndicatorColor || `disabled` }}
          />
        </HStack>
        {children}
      </HStack>
    </CardHeader>
  );
}
