import { ListingState } from "@/graphql";
import { Status } from "@/modules/Status";
import { t } from "i18next";
import { match } from "ts-pattern";

export const listingStateNames: Record<ListingState, string> = {
  [ListingState.Closed]: t(`closed`),
  [ListingState.ConditionallySold]: t(`conditionally_sold`),
  [ListingState.Expired]: t(`expired`),
  [ListingState.InReview]: t(`in_review`),
  [ListingState.Open]: t(`open`),
  [ListingState.Rejected]: t(`rejected`),
  [ListingState.Withdrawn]: t(`withdrawn`),
};

export function ListingStatus({ state }: { state: ListingState }) {
  return match(state)
    .with(ListingState.Closed, () => (
      <Status.Indicator
        text={listingStateNames[ListingState.Closed]}
        indicatorProps={{
          bg: `sky.600`,
        }}
      />
    ))
    .with(ListingState.ConditionallySold, () => (
      <Status.Indicator
        text={listingStateNames[ListingState.ConditionallySold]}
        indicatorProps={{
          bg: `sky.600`,
        }}
      />
    ))
    .with(ListingState.Expired, () => (
      <Status.Indicator
        text={listingStateNames[ListingState.Expired]}
        indicatorProps={{
          bg: `archived`,
        }}
      />
    ))
    .with(ListingState.InReview, () => (
      <Status.Indicator
        text={listingStateNames[ListingState.InReview]}
        indicatorProps={{
          bg: `pending`,
        }}
      />
    ))
    .with(ListingState.Open, () => (
      <Status.Indicator
        text={listingStateNames[ListingState.Open]}
        indicatorProps={{
          bg: `active`,
        }}
      />
    ))
    .with(ListingState.Rejected, () => (
      <Status.Indicator
        text={listingStateNames[ListingState.Rejected]}
        indicatorProps={{
          bg: `archived`,
        }}
      />
    ))
    .with(ListingState.Withdrawn, () => (
      <Status.Indicator
        text={listingStateNames[ListingState.Withdrawn]}
        indicatorProps={{
          bg: `archived`,
        }}
      />
    ))
    .otherwise(() => ``);
}
