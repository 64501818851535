import { BidCompanyFragment } from "@/graphql";
import { Status } from "@/modules/Status";
import { Card, CardBody, CardHeader, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

type BidSecurtitySpecialistCardProps = {
  company: BidCompanyFragment;
};

export function BidSecuritySpecialistCard({
  company,
}: BidSecurtitySpecialistCardProps) {
  const { t } = useTranslation();
  const { securitySpecialist } = company || {};
  return (
    <Card w="full">
      <CardHeader textStyle="colfax-22-medium">
        {t(`security_specialist`)}
      </CardHeader>
      <CardBody>
        {securitySpecialist?.user.name ? (
          <Text>{securitySpecialist?.user.name}</Text>
        ) : (
          <Status.Empty />
        )}
      </CardBody>
    </Card>
  );
}
