import { ListingState } from "@/graphql";

const LISTING_STATE_INDICATOR_COLOR_MAP = {
  [ListingState.Closed]: `archived`,
  [ListingState.ConditionallySold]: `active`,
  [ListingState.Expired]: `archived`,
  [ListingState.InReview]: `pending`,
  [ListingState.Open]: `active`,
  [ListingState.Rejected]: `archived`,
  [ListingState.Withdrawn]: `archived`,
};

export function getListingStateIndicatorColor(state?: ListingState) {
  return state ? LISTING_STATE_INDICATOR_COLOR_MAP[state] : `disabled`;
}
