import { ListingDetailsContext } from "@/features/Listing";
import { ListingPageFragment } from "@/graphql";
import { CardTable } from "@/modules/CardTable";
import { CurrencyFormat, formatCurrency } from "@/modules/NumeralFormat";
import { Status } from "@/modules/Status";
import { useColors } from "@/modules/Theme";
import {
  Card,
  CardBody,
  CardHeader,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import i18next from "i18next";
import { ReactNode, useContext } from "react";
import { useTranslation } from "react-i18next";

const shareTypeLabelFallback = i18next.t(`share_type_key_preferred`);

function mapListingPriceData(listing?: Partial<ListingPageFragment>) {
  const {
    pricePerShare,
    numSharesOriginal,
    numSharesOriginalRounded,
    shareSeriesMakeup,
  } = listing || {};
  const pricePerShareFloat = (pricePerShare || 0) / 100;

  return {
    pricePerShare: formatCurrency(pricePerShareFloat),
    numSharesActual: formatCurrency(
      numSharesOriginal,
      CurrencyFormat.noSymbolPrecision0,
    ),
    numSharesRounded:
      numSharesOriginalRounded &&
      formatCurrency(
        numSharesOriginalRounded,
        CurrencyFormat.noSymbolPrecision0,
      ),
    totalActual: formatCurrency((numSharesOriginal ?? 0) * pricePerShareFloat),
    totalRounded:
      numSharesOriginalRounded &&
      formatCurrency((numSharesOriginalRounded ?? 0) * pricePerShareFloat),
    shareSeriesMakeup: shareSeriesMakeup?.map((seriesItem) => {
      const { numShares, shareSeries } = seriesItem || {};
      return {
        value: formatCurrency(numShares, CurrencyFormat.noSymbolPrecision0),
        label: i18next.t(
          `share_series_type_${shareSeries?.toLowerCase()}`,
          shareTypeLabelFallback,
        ),
      };
    }),
  };
}

function Label({ children }: { children: ReactNode }) {
  const [grey600] = useColors([`grey.600`]);
  return (
    <Text color={grey600} fontSize={14}>
      {children}
    </Text>
  );
}

export function ListingPriceCardTable() {
  const { t } = useTranslation();
  const { listing } = useContext(ListingDetailsContext);
  const {
    numSharesActual,
    numSharesRounded,
    pricePerShare,
    totalActual,
    totalRounded,
    shareSeriesMakeup,
  } = mapListingPriceData(listing);

  return (
    <CardTable w="full" columns={3}>
      <Card variant="table">
        <CardHeader>{t(`number_of_shares`)}</CardHeader>
        <CardBody>
          <VStack alignItems="flex-start" gap={4}>
            <HStack>
              <Label>{t(`actual`)}</Label>
              <Text fontWeight="500">{numSharesActual}</Text>
            </HStack>
            <HStack>
              <Label>{t(`rounded`)}</Label>
              {numSharesRounded ? (
                <Text fontWeight="500">{numSharesRounded}</Text>
              ) : (
                <Status.Empty />
              )}
            </HStack>
            {shareSeriesMakeup?.length && (
              <VStack alignItems="flex-start" gap={1}>
                {shareSeriesMakeup.map(({ label, value }) => (
                  <Label
                    key={`${label}-${value}`}
                  >{`(${value} ${label})`}</Label>
                ))}
              </VStack>
            )}
          </VStack>
        </CardBody>
      </Card>
      <Card variant="table">
        <CardHeader>{t(`price_per_share`)}</CardHeader>
        <CardBody>
          <Text fontWeight="500">{pricePerShare}</Text>
        </CardBody>
      </Card>
      <Card variant="table">
        <CardHeader>{t(`total`)}</CardHeader>
        <CardBody>
          <VStack alignItems="flex-start" gap={4}>
            <HStack>
              <Label>{t(`actual`)}</Label>
              <Text fontWeight="500">{totalActual}</Text>
            </HStack>
            <HStack>
              <Label>{t(`rounded`)}</Label>
              {totalRounded ? (
                <Text fontWeight="500">{totalRounded}</Text>
              ) : (
                <Status.Empty />
              )}
            </HStack>
          </VStack>
        </CardBody>
      </Card>
    </CardTable>
  );
}
