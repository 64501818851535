import {
  ListingPageFragment,
  useListingUpdateListingIgnoreInPricingMutation,
} from "@/graphql";
import { useCustomToast } from "@/modules/Toast";
import { Switch } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

type ListingListingIgnoreInPricingToggleProps = {
  listing: ListingPageFragment;
};

export function ListingIgnoreInPricingToggle({
  listing: { id, displayId, ignoreInPricing },
}: ListingListingIgnoreInPricingToggleProps) {
  const { t } = useTranslation();
  const { successToast, errorToast } = useCustomToast();

  const [{ fetching }, executeIgnoreInPricing] =
    useListingUpdateListingIgnoreInPricingMutation();

  return (
    <Switch
      isDisabled={fetching}
      colorScheme="green"
      isChecked={!ignoreInPricing}
      onChange={async () => {
        try {
          const { data, error } = await executeIgnoreInPricing({
            listingId: id,
            ignoreInPricing: !ignoreInPricing,
          });

          const ignoreInPricingValue =
            data?.updateListingIgnoreInPricing.listing?.ignoreInPricing;

          if (error) {
            return errorToast(t(`error_toggling_visibility`));
          }

          return successToast(
            t(
              ignoreInPricingValue
                ? `listing_hidden_from_price_graph`
                : `listing_shown_on_price_graph`,
              {
                displayId,
              },
            ),
          );
        } catch (_) {
          return errorToast(t(`error_toggling_visibility`));
        }
      }}
    />
  );
}
