import { ListingDetailsContext } from "@/features/Listing";
import { ListingPageFragment, ShareSeriesType } from "@/graphql";
import { CardTable } from "@/modules/CardTable";
import { Status } from "@/modules/Status";
import { useColors } from "@/modules/Theme";
import {
  Card,
  CardBody,
  CardHeader,
  Grid,
  GridItem,
  Text,
} from "@chakra-ui/react";
import i18next from "i18next";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

const shareTypeMap = {
  preferred: [
    ShareSeriesType.Seed,
    ShareSeriesType.A,
    ShareSeriesType.B,
    ShareSeriesType.C,
    ShareSeriesType.D,
    ShareSeriesType.E,
    ShareSeriesType.F,
    ShareSeriesType.G,
    ShareSeriesType.H,
    ShareSeriesType.I,
    ShareSeriesType.J,
    ShareSeriesType.K,
    ShareSeriesType.L,
  ],
  common: [
    ShareSeriesType.Common,
    ShareSeriesType.Options,
    ShareSeriesType.CommonOptions,
  ],
  restricted_stock_units: [ShareSeriesType.RestrictedStockUnits],
};

function getShareType(
  shareSeriesMakeup: ListingPageFragment["shareSeriesMakeup"],
) {
  if (!shareSeriesMakeup?.length) {
    return null;
  }
  const shareTypeKey = Object.keys(shareTypeMap).find((key) =>
    shareSeriesMakeup.every((seriesItem) =>
      shareTypeMap[key as keyof typeof shareTypeMap].includes(
        seriesItem?.shareSeries as ShareSeriesType,
      ),
    ),
  );
  return shareTypeKey || `mix`;
}

const MAPPED_TABLE_DATA = [
  {
    label: i18next.t(`share_type`),
    value: ({ shareSeriesMakeup }: Partial<ListingPageFragment> = {}) => {
      const shareTypeKey = getShareType(shareSeriesMakeup);
      return shareTypeKey
        ? (i18next.t(
            `share_type_key_${shareTypeKey}` as unknown as TemplateStringsArray,
          ) as string)
        : null;
    },
  },
  {
    label: i18next.t(`solicitation`),
    value: ({ solicited }: Partial<ListingPageFragment> = {}) =>
      i18next.t(solicited ? `solicited` : `unsolicited`),
  },
  {
    label: i18next.t(`placed_by_hiive`),
    value: ({ seller }: Partial<ListingPageFragment> = {}) =>
      i18next.t(seller?.isHiiveUser ? `yes` : `no`),
  },
  {
    label: i18next.t(`structure`),
    value: ({ transferMethod }: Partial<ListingPageFragment> = {}) =>
      transferMethod
        ? (i18next.t(
            `structure_${transferMethod.toLowerCase()}` as unknown as TemplateStringsArray,
          ) as string)
        : null,
  },
  {
    label: i18next.t(`affiliated`),
    value: ({ affiliate }: Partial<ListingPageFragment> = {}) =>
      i18next.t(affiliate ? `yes` : `no`),
  },
];

export function ListingDetailsCardTable() {
  const { t } = useTranslation();
  const [grey600] = useColors([`grey.600`]);
  const { listing } = useContext(ListingDetailsContext);

  return (
    <CardTable w="full" columns={1}>
      <Card variant="table">
        <CardHeader>{t(`listing_details`)}</CardHeader>
        <CardBody>
          <Grid templateColumns="repeat(3, 1fr)" gap={6} width="100%">
            {MAPPED_TABLE_DATA.map(({ label, value }) => (
              <GridItem key={label}>
                <Text fontWeight="500" color={grey600}>
                  {label}
                </Text>
                <Text>{value(listing) || <Status.Empty />}</Text>
              </GridItem>
            ))}
          </Grid>
        </CardBody>
      </Card>
    </CardTable>
  );
}
