import {
  ListingDetailsContext,
  ListingDetailsProvider,
} from "@/features/Listing";
import { ErrorWrapper } from "@/modules/Error";
import { DetailPageLayout } from "@/modules/Layout";
import { useContext } from "react";
import {
  ListingOverview,
  ListingRightPanel,
  ListingPageSkeleton,
} from "./components";

function ListingPageContent() {
  const { error, fetching, listing } = useContext(ListingDetailsContext);

  if (fetching) {
    return <ListingPageSkeleton />;
  }

  return (
    <ErrorWrapper error={error?.message}>
      {listing?.id ? (
        <DetailPageLayout
          isTwoColumn
          mainPanel={<ListingOverview />}
          rightPanel={<ListingRightPanel />}
        />
      ) : null}
    </ErrorWrapper>
  );
}

export default function ListingPage({ id }: { id: string }) {
  return (
    <ListingDetailsProvider id={id}>
      <ListingPageContent />
    </ListingDetailsProvider>
  );
}
