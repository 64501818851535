import { CardTable } from "@/modules/CardTable";
import { Status } from "@/modules/Status";
import {
  Card,
  CardBody,
  CardHeader,
  Grid,
  GridItem,
  Text,
} from "@chakra-ui/react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

type BidDetailsTableProps = {
  affiliated: boolean;
  solicited: boolean;
  placedByHiive: boolean;
};

const MAPPED_TABLE_DATA = [
  {
    label: i18next.t(`solicitation`),
    value: ({ solicited }: BidDetailsTableProps) =>
      solicited ? i18next.t(`solicited`) : i18next.t(`unsolicited`),
  },
  {
    label: i18next.t(`placed_by_hiive`),
    value: ({ placedByHiive }: BidDetailsTableProps) =>
      placedByHiive ? i18next.t(`yes`) : i18next.t(`no`),
  },
  {
    label: i18next.t(`affiliated`),
    value: ({ affiliated }: BidDetailsTableProps) =>
      affiliated ? i18next.t(`yes`) : i18next.t(`no`),
  },
];

export function BidDetailsTable(props: BidDetailsTableProps) {
  const { t } = useTranslation();

  return (
    <CardTable w="full" columns={1}>
      <Card variant="table">
        <CardHeader>{t(`bid_details`)}</CardHeader>
        <CardBody>
          <Grid templateColumns="repeat(3, 1fr)" gap={6} w="full">
            {MAPPED_TABLE_DATA.map(({ label, value }) => (
              <GridItem key={label}>
                <Text fontWeight="500" color="grey.600">
                  {label}
                </Text>
                <Text>{value(props) || <Status.Empty />}</Text>
              </GridItem>
            ))}
          </Grid>
        </CardBody>
      </Card>
    </CardTable>
  );
}
