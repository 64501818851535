import { CardTable } from "@/modules/CardTable";
import { Status } from "@/modules/Status";
import { Card, CardBody, CardHeader, Text } from "@chakra-ui/react";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export function ListingNotesCardTable({ children }: { children: ReactNode }) {
  const { t } = useTranslation();
  return (
    <CardTable w="full" columns={1}>
      <Card variant="table">
        <CardHeader>{t(`additional_listing_notes`)}</CardHeader>
        <CardBody>
          {children ? <Text>{children}</Text> : <Status.Empty />}
        </CardBody>
      </Card>
    </CardTable>
  );
}
