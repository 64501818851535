import { Card, CardBody, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { CompanyInfoClipboard } from "@/features/Company";
import {
  ListingDetailsContext,
  LowercasedListingState,
  getListingStateIndicatorColor,
} from "@/features/Listing";
import { ListingsTableRowActions } from "@/features/Listings";
import { EntityDetailsCardHeader } from "@/modules/EntityDetailsCardHeader";

import { FeeDiscountEntityType } from "@/graphql";
import { useFeeDiscountManagement } from "@/modules/FeeDiscountsCard";
import { useContext } from "react";
import { ListingDetailsCardTable } from "./ListingDetailsCardTable";
import { ListingFeeBreakdownCardTable } from "./ListingFeeBreakdownCardTable";
import { ListingNotesCardTable } from "./ListingNotesCardTable";
import { ListingPriceCardTable } from "./ListingPriceCardTable";
import { ListingSellerCardTable } from "./ListingSellerCardTable";

export function ListingCard() {
  const { t } = useTranslation();
  const { listing } = useContext(ListingDetailsContext);
  const { isFeeDiscountActionAllowedForEntity } = useFeeDiscountManagement({
    entityType: FeeDiscountEntityType.Listing,
    entityId: listing?.id || ``,
    listing,
    ...listing,
  });

  if (!listing?.id) {
    return null;
  }

  const { id, displayId, solicited, state, company } = listing;
  const { status: companyState } = company;
  const statusIndicatorColor = getListingStateIndicatorColor(state);

  return (
    <Card w="full">
      <EntityDetailsCardHeader
        header={`${t(`listing`)} ${displayId}`}
        status={t(state.toLowerCase() as LowercasedListingState)}
        statusIndicatorColor={statusIndicatorColor}
      >
        <ListingsTableRowActions
          listingId={id}
          solicited={solicited}
          state={state}
          companyState={companyState}
          showActionButtonTitle
        />
      </EntityDetailsCardHeader>
      <CardBody>
        <CompanyInfoClipboard
          logoUrl={company.logoUrl}
          companyName={company.name}
          copyToClipboardItems={[{ label: t(`database_id`), value: id }]}
        />
        <VStack rowGap={6} my={6} alignItems="baseline">
          <ListingPriceCardTable />
          {isFeeDiscountActionAllowedForEntity && (
            <ListingFeeBreakdownCardTable />
          )}
          <ListingSellerCardTable />
          <ListingDetailsCardTable />
          <ListingNotesCardTable>{listing?.otherDetails}</ListingNotesCardTable>
        </VStack>
      </CardBody>
    </Card>
  );
}
