import { CardTable } from "@/modules/CardTable";
import { Card, CardBody, CardHeader, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

type BidPriceCardTableProps = {
  formattedNumShares: string;
  formattedPricePerShare: string;
  formattedTotal: string;
};

export function BidPriceCardTable({
  formattedNumShares,
  formattedPricePerShare,
  formattedTotal,
}: BidPriceCardTableProps) {
  const { t } = useTranslation();

  return (
    <CardTable w="full" columns={3}>
      <Card variant="table">
        <CardHeader>{t(`number_of_shares`)}</CardHeader>
        <CardBody>
          <Text fontWeight="500">{formattedNumShares}</Text>
        </CardBody>
      </Card>
      <Card variant="table">
        <CardHeader>{t(`price_per_share`)}</CardHeader>
        <CardBody>
          <Text fontWeight="500">{formattedPricePerShare}</Text>
        </CardBody>
      </Card>
      <Card variant="table">
        <CardHeader>{t(`total`)}</CardHeader>
        <CardBody>
          <Text fontWeight="500">{formattedTotal}</Text>
        </CardBody>
      </Card>
    </CardTable>
  );
}
