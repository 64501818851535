import { BidData } from "@/features/Bid";
import { VStack } from "@chakra-ui/react";
import { BidCard } from "./BidCard";
import { BidListingsCard } from "./BidListingsCard";

type BidOverviewProps = {
  bid: BidData;
};

export function BidOverview({ bid }: BidOverviewProps) {
  const [originalBid] = bid;
  return (
    <VStack gap={4}>
      <BidCard bid={bid} />
      <BidListingsCard bid={originalBid} />
    </VStack>
  );
}
