import { VStack } from "@chakra-ui/react";
import { ListingCard } from "./ListingCard";
import { ListingTransactionsCard } from "./ListingTransactionsCard";
import { ListingBidsCard } from "./ListingBidsCard";

export default function ListingOverview() {
  return (
    <VStack gap={4}>
      <ListingCard />
      <ListingBidsCard />
      <ListingTransactionsCard />
    </VStack>
  );
}
