import { RESET_PAGINATION_PARAMS } from "@/constants";
import { CompanyCreateOtherCardSecuritySpecialistFragment } from "@/graphql";
import { Checkbox } from "@/modules/Form";
import { TableContext } from "@/modules/SimpleTable";
import {
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  SimpleGrid,
  GridItem,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { CaretDown } from "@phosphor-icons/react";
import { useCallback, useContext } from "react";
import { SubmitHandler, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

type ListingFormValues = {
  searchText: string;
  securitySpecialistCheckbox?: Record<string, boolean>;
};

interface ListingsSecuritySpecialistFilterButtonProps {
  securitySpecialists: CompanyCreateOtherCardSecuritySpecialistFragment[];
}

export default function ListingsSecuritySpecialistFilterButton({
  securitySpecialists,
}: ListingsSecuritySpecialistFilterButtonProps) {
  const { setVariables, control, register, handleSubmit } =
    useContext(TableContext);
  const { t } = useTranslation();
  const { isOpen, onClose, onToggle } = useDisclosure();
  const formValues = useWatch({ control });

  const onSubmit: SubmitHandler<ListingFormValues> = useCallback(
    (data) => {
      onToggle();
      setVariables((prevVariables) => ({
        ...prevVariables,
        filterBy: {
          searchText: prevVariables.filterBy?.searchText,
          securitySpecialistIds: Object.keys(
            data.securitySpecialistCheckbox as object,
          ).reduce<string[]>(
            (result, id) =>
              !!data.securitySpecialistCheckbox &&
              data.securitySpecialistCheckbox[id]
                ? [...result, id]
                : result,
            [],
          ),
        },
        ...RESET_PAGINATION_PARAMS,
      }));
    },
    [onToggle, setVariables],
  );

  return (
    <Popover returnFocusOnClose={false} isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button
          variant="outline"
          rightIcon={<CaretDown />}
          w={150}
          onClick={onToggle}
        >
          {t(`security_specialist`)}
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverBody as="form" onSubmit={handleSubmit(onSubmit)}>
            <VStack p={2} gap={2} alignItems="flex-start">
              {securitySpecialists.map((securitySpecialist) => (
                <Checkbox
                  label={securitySpecialist.name}
                  key={securitySpecialist.id}
                  isChecked={
                    !!formValues?.securitySpecialistCheckbox
                      ? !!formValues?.securitySpecialistCheckbox[
                          securitySpecialist.id
                        ]
                      : false
                  }
                  {...register(
                    `securitySpecialistCheckbox.${securitySpecialist.id}` as "securitySpecialistCheckbox.string",
                  )}
                />
              ))}
            </VStack>
            <SimpleGrid columns={2} columnGap={2} mt={2}>
              <GridItem colSpan={1}>
                <Button variant="outline" size="lg" w="full" onClick={onClose}>
                  {t(`cancel`)}
                </Button>
              </GridItem>
              <GridItem colSpan={1}>
                <Button type="submit" size="lg" w="full">
                  {t(`apply`)}
                </Button>
              </GridItem>
            </SimpleGrid>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
