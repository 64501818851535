import { useTranslation } from "react-i18next";

import { CardTable } from "@/modules/CardTable";
import { FeeBreakdownSkeleton } from "@/modules/FeeBreakdown";
import { DetailPageLayout } from "@/modules/Layout";
import { Skeleton } from "@/modules/Skeleton";
import { Table } from "@/modules/Table";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Grid,
  GridItem,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { CaretRight } from "@phosphor-icons/react";

function ListingMainPanelSkeleton() {
  const { t } = useTranslation();

  return (
    <VStack gap={4}>
      <Card w="full">
        <CardHeader as={HStack} gap={4} alignItems="center">
          <Text textStyle="colfax-22-medium">{t(`listing`)}</Text>
          <Skeleton h={4} w={100} />
          <Box flexGrow={1} />
          <HStack spacing={4}>
            <HStack>
              <Skeleton h={4} w={100} />
              <Text color="grey.300" mx={0.5}>
                |
              </Text>
              <Skeleton h={4} w={100} />
              <Skeleton h={10} w={100} />
            </HStack>
          </HStack>
        </CardHeader>
        <CardBody>
          <VStack alignItems="flex-start" gap={6}>
            <VStack alignItems="flex-start">
              <HStack alignItems="center" gap={4}>
                <Skeleton h={7} w={7} />
                <Skeleton h={4} w={219} />
              </HStack>
              <Skeleton h={3} w={400} my={2} />
            </VStack>

            <CardTable w="full" columns={3}>
              <Card variant="table">
                <CardHeader>{t(`number_of_shares`)}</CardHeader>
                <CardBody>
                  <VStack align="flex-start" gap={4}>
                    <HStack>
                      <Text textStyle="colfax-14-regular">{t(`actual`)}</Text>
                      <Skeleton h={4} w={40} />
                    </HStack>
                    <HStack>
                      <Text textStyle="colfax-14-regular">{t(`rounded`)}</Text>
                      <Skeleton h={4} w={40} />
                    </HStack>
                    <Skeleton h={2} w={40} />
                  </VStack>
                </CardBody>
              </Card>
              <Card variant="table">
                <CardHeader>{t(`price_per_share`)}</CardHeader>
                <CardBody>
                  <Skeleton h={4} w={40} />
                </CardBody>
              </Card>
              <Card variant="table">
                <CardHeader>{t(`total`)}</CardHeader>
                <CardBody>
                  <VStack alignItems="flex-start" gap={4}>
                    <HStack>
                      <Text textStyle="colfax-14-regular">{t(`actual`)}</Text>
                      <Skeleton h={4} w={40} />
                    </HStack>
                    <HStack>
                      <Text textStyle="colfax-14-regular">{t(`rounded`)}</Text>
                      <Skeleton h={4} w={40} />
                    </HStack>
                  </VStack>
                </CardBody>
              </Card>
            </CardTable>

            <FeeBreakdownSkeleton />

            <CardTable w="full" columns={1}>
              <Card variant="table">
                <CardHeader>{t(`seller_details`)}</CardHeader>
                <CardBody>
                  <HStack gap={4} flexWrap="wrap">
                    <Box
                      backgroundColor="grey.25"
                      borderColor="grey.50"
                      borderWidth={1}
                      borderRadius={6}
                      p={3}
                      maxW={400}
                      flex={1}
                    >
                      <VStack alignItems="flex-start" gap={1} marginBottom={4}>
                        <Skeleton h={4} w={20} />
                        <Skeleton h={5} w={20} />
                        <Skeleton h={4} w={40} />
                      </VStack>

                      <Button variant="outline" size="md">
                        <HStack>
                          <Text textStyle="colfax-12-medium">{t`full_details`}</Text>
                          <CaretRight size={14} />
                        </HStack>
                      </Button>
                    </Box>
                  </HStack>
                </CardBody>
              </Card>
            </CardTable>

            <CardTable w="full" columns={1}>
              <Card variant="table">
                <CardHeader>{t(`listing_details`)}</CardHeader>
                <CardBody>
                  <Grid templateColumns="repeat(3, 1fr)" gap={6} width="100%">
                    <GridItem>
                      <Text fontWeight="500" color="grey.600">
                        {t(`share_type`)}
                      </Text>
                      <Skeleton h={4} w={20} />
                    </GridItem>

                    <GridItem>
                      <Text fontWeight="500" color="grey.600">
                        {t(`structure`)}
                      </Text>
                      <Skeleton h={4} w={20} />
                    </GridItem>

                    <GridItem>
                      <Text fontWeight="500" color="grey.600">
                        {t(`solicitation`)}
                      </Text>
                      <Skeleton h={4} w={20} />
                    </GridItem>

                    <GridItem>
                      <Text fontWeight="500" color="grey.600">
                        {t(`affiliated`)}
                      </Text>
                      <Skeleton h={4} w={20} />
                    </GridItem>

                    <GridItem>
                      <Text fontWeight="500" color="grey.600">
                        {t(`placed_by_hiive`)}
                      </Text>
                      <Skeleton h={4} w={20} />
                    </GridItem>
                  </Grid>
                </CardBody>
              </Card>
            </CardTable>

            <CardTable w="full" columns={1}>
              <Card variant="table">
                <CardHeader>{t(`additional_listing_notes`)}</CardHeader>
                <CardBody>
                  <Skeleton h={4} w={80} />
                </CardBody>
              </Card>
            </CardTable>
          </VStack>
        </CardBody>
      </Card>

      <Card w="full">
        <CardHeader textStyle="colfax-22-medium">
          {t(`transactions_on_this_listing`)}
        </CardHeader>
        <CardBody>
          <Table.Skeleton columns={6} rows={2} />
        </CardBody>
      </Card>
    </VStack>
  );
}

function ListingRightPanelSkeleton() {
  const { t } = useTranslation();

  return (
    <VStack gap={4}>
      <Card w="full">
        <CardHeader w="full" as={HStack} justifyContent="space-between">
          <Text textStyle="colfax-22-medium">{t(`dates_and_status`)}</Text>
        </CardHeader>
        <CardBody>
          <VStack gap={4}>
            <HStack justifyContent="space-between" w="full">
              <Text
                textStyle="colfax-14-medium-uncased"
                textTransform="uppercase"
              >
                {t(`placed`)}
              </Text>
              <Skeleton h={4} w={40} />
            </HStack>

            <HStack justifyContent="space-between" w="full">
              <Text
                textStyle="colfax-14-medium-uncased"
                textTransform="uppercase"
              >
                {t(`expires`)}
              </Text>
              <Skeleton h={4} w={40} />
            </HStack>

            <HStack justifyContent="space-between" w="full">
              <Text
                textStyle="colfax-14-medium-uncased"
                textTransform="uppercase"
              >
                {t(`status`)}
              </Text>
              <Skeleton h={4} w={40} />
            </HStack>

            <HStack justifyContent="space-between" w="full">
              <Text
                textStyle="colfax-14-medium-uncased"
                textTransform="uppercase"
              >
                {t(`show_in_price_graph`)}
              </Text>
              <Skeleton h={4} w={40} />
            </HStack>
          </VStack>
        </CardBody>
      </Card>

      <Card w="full">
        <CardHeader w="full" as={HStack} justifyContent="space-between">
          <Text textStyle="colfax-22-medium">{t(`security_specialist`)}</Text>
        </CardHeader>
        <CardBody>
          <Skeleton h={4} w={40} />
        </CardBody>
      </Card>

      <Card w="full">
        <CardHeader w="full" as={HStack} justifyContent="space-between">
          <Text textStyle="colfax-22-medium">{t(`fee_discounts`)}</Text>
        </CardHeader>
        <CardBody>
          <VStack gap={6}>
            <Skeleton h={4} w="full" />
            <Skeleton h={4} w="full" />
          </VStack>
        </CardBody>
      </Card>
    </VStack>
  );
}

export default function ListingPageSkeleton() {
  return (
    <DetailPageLayout
      isTwoColumn
      mainPanel={<ListingMainPanelSkeleton />}
      rightPanel={<ListingRightPanelSkeleton />}
    />
  );
}
