import { ListingDetailsContext } from "@/features/Listing";
import { Status } from "@/modules/Status";
import { Card, CardBody, CardHeader, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

export function ListingSecuritySpecialistCard() {
  const { t } = useTranslation();
  const { listing } = useContext(ListingDetailsContext);
  const { securitySpecialist } = listing?.company || {};
  return (
    <Card w="full">
      <CardHeader textStyle="colfax-22-medium">
        {t(`security_specialist`)}
      </CardHeader>
      <CardBody>
        {securitySpecialist?.user.name ? (
          <Text>{securitySpecialist?.user.name}</Text>
        ) : (
          <Status.Empty />
        )}
      </CardBody>
    </Card>
  );
}
