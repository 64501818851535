import { ListingDetailsContext } from "@/features/Listing";
import { Table, TableColumns } from "@/modules/Table";
import { Card, CardBody, CardHeader } from "@chakra-ui/react";
import i18next from "i18next";
import { ReactNode, useContext, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  BidIdTableCell,
  SharesPPSTableCell,
  BuyerEmailTableCell,
  PlacedOnTableCell,
  StateTableCell,
} from "@/features/Bids";
import { BidsTableNodeFragment, ListingPageFragment } from "@/graphql";
import { useRouter } from "next/router";
import { Row } from "@tanstack/react-table";
import { BidTableRowActions } from "@/features/Bid";

const EMPTY_STATE_BID = [{}];

type ListingBidsTableColumn = Record<
  "displayId" | "sharesPPS" | "buyerEmail" | "insertedAt" | "state",
  ReactNode
>;

type ListingBidsTableData = BidsTableNodeFragment;

const columns: TableColumns<ListingBidsTableColumn, ListingBidsTableData> = {
  displayId: {
    header: () => i18next.t(`bid_id`),
    cell: ({ cell }) => <BidIdTableCell cell={cell} />,
    enableSorting: false,
  },
  sharesPPS: {
    header: () => i18next.t(`shares_pps`),
    cell: ({ cell }) => <SharesPPSTableCell cell={cell} />,
    enableSorting: false,
  },
  buyerEmail: {
    header: () => i18next.t(`buyer_email`),
    cell: ({ cell }) => <BuyerEmailTableCell cell={cell} />,
    enableSorting: false,
  },
  insertedAt: {
    header: () => i18next.t(`placed_on`),
    cell: ({ cell }) => <PlacedOnTableCell cell={cell} />,
    enableSorting: false,
  },
  state: {
    header: () => i18next.t(`state`),
    cell: ({ cell }) => <StateTableCell cell={cell} />,
    enableSorting: false,
  },
};

const sortBids = (bids: ListingPageFragment["bids"]) =>
  bids.toSorted(
    (
      { placedAt: leftPlacedAt, counteredAt: leftCounteredAt },
      { placedAt: rightPlacedAt, counteredAt: rightCounteredAt },
    ) =>
      new Date(rightCounteredAt ?? rightPlacedAt).getTime() -
      new Date(leftCounteredAt ?? leftPlacedAt).getTime(),
  );

export function ListingBidsCard() {
  const { t } = useTranslation();
  const { push } = useRouter();
  const { listing } = useContext(ListingDetailsContext);

  const bidsLength = listing?.bids?.length ?? 0;

  const data = useMemo(
    () =>
      (listing && bidsLength > 0
        ? sortBids(listing.bids)
        : EMPTY_STATE_BID) as ListingBidsTableData[],
    [listing, bidsLength],
  );

  const onRowClick = useCallback(
    (bidRow: Row<ListingBidsTableData>) => {
      const { id } = bidRow.original;
      push(`/bids/${id}`);
    },
    [push],
  );

  const renderRowActions = useCallback(
    (bidRow: Row<ListingBidsTableData>) => (
      <BidTableRowActions
        bidId={bidRow.original.id}
        solicited={bidRow.original.solicited ?? false}
        companyState={listing?.company.status}
        state={(bidRow.original as ListingBidsTableData).state}
      />
    ),
    [listing?.company.status],
  );

  const additionalProps =
    bidsLength > 0
      ? {
          onRowClick,
          renderRowActions,
        }
      : {};

  return (
    <Card w="full">
      <CardHeader textStyle="colfax-22-medium">
        {t(`bids_on_this_listing`)}
      </CardHeader>
      <CardBody>
        <Table<ListingBidsTableColumn[], ListingBidsTableData[]>
          columns={columns}
          data={data}
          borderColor="grey.100"
          borderStyle="solid"
          borderBottomWidth={1}
          borderLeftWidth={1}
          borderRightWidth={1}
          {...additionalProps}
        />
      </CardBody>
    </Card>
  );
}
